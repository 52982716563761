import React, { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  PostCard,
  RequestCard,
  Loader,
  PaginationContainer,
} from "../../components";
import { getPaginatedPostsRecord } from "../../redux/actions";
import { scrollToTop } from "../../utils/navUtils";

const RentalsLandingScreen = ({coords}) => {
  const {
    forRentRequests,
    sortedPosts,
    currentTab,
    currentPage,
    numPerPage,
    numOfPages,
    paginatedPosts
  } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
    const { t } = useTranslation();

  useEffect(() => {
    scrollToTop()
    dispatch(getPaginatedPostsRecord({ currentPage, numPerPage, sortedPosts }));
   
  }, [dispatch,sortedPosts,currentPage,numOfPages,numPerPage]);;

  return (
    <Suspense fallback={<Loader />}>
      <div className="home__screen" id="top">
        <Helmet>
        <title>Real Estate & Homes for Sale in Cameroon,Rentals,Guest House</title>
          <meta
            name="description"
            content="Find guest houses for breakfast and bed (B&B), houses to rent,houses and plots of land for sell,and solve all your real estate problems.We have brought thousands of homes,plots of land,guest houses and real estate agents closer to you.From the comfort of your home and with your phone or laptop,access our thousand of property and speak with real estate agents with one click"
          />
          <meta
            name="keywords"
            content="real estate in Cameroon, homes for sale in Cameroon,rentals,guest house "
          />
        </Helmet>

        {currentTab === "houses" ? (
          <>
            <br />
            <div className="home__postsContainer text-secondary">
              <div className="posts__container-items">
                { paginatedPosts?.map((post) => (
                  <PostCard item={post} id={post?.id} key={post?.id} />
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="home__clients-requests-container">
              <div className="home__clients-requests p-4">
                {forRentRequests?.map((request) => (
                  <RequestCard item={request} key={request?.id} />
                ))}
              </div>
            </div>
          </>
        )}
        <br />

        <div className="d-flex justify-content-between align-items-center w-100 px-4">
          <div className="">
            <p className="pagination_note">
             {t("current page")} <span>{currentPage}{" "}</span>/{" "}
              <span>{numOfPages}</span>
            </p>
          </div>
          <PaginationContainer />
        </div>
      </div>
    </Suspense>
  );
};

export default RentalsLandingScreen
