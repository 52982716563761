import React from "react";
import { useTranslation } from "react-i18next";

const CustomSelectCities = ({
  handleInputChange,
  value,
  data,
  labelId,
  selectId,
  labelName,
  inputName,
  optionValue,
  validator
}) => {
  const { t } = useTranslation();
  return (
    <>
     <div className="custom__input">
      <select
        className="custom__select"
        labelid={labelId}
        id={selectId}
        label={labelName}
        name={inputName}
        onChange={handleInputChange}
        value={value}
        required
      >
        {!data ? (
          <option value={optionValue}>{t("no cities")}</option>
        ) : (
          value === "" && (
            <option value={optionValue}>{t("select city")}</option>
          )
        )}

        {data?.map((option, index) => {
          return (
            <option key={index} value={option} style={{ color: "black" }}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
    <label className="w-100 text-danger">
            {validator?.message(inputName, value, `required|${inputName}`, {
              messages: {
                required: t("required"),
              },
            })}
          </label>
    </>
  );
};

export default CustomSelectCities;
