import React,{ useState,useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector,useDispatch } from "react-redux"
import MyRequestScreen from '../MyRequest/MyRequestScreen'
import Favorites from '../favorites/Favorites'
import "./styles.css"
import { getFavoritesAction } from '../../../../redux/actions';

const ClientProfile = () => {
const [currentTab, setCurrentTab] = useState('form')
  const {  favorites } = useSelector((state) => state.posts)
const { t } = useTranslation();
  const dispatch = useDispatch()
 const { user } = useSelector((state) => state.user)
  const { id } = user || {};

  useEffect(() => {
     dispatch(getFavoritesAction(id))
    }, [dispatch,favorites,id]);
    
const handleSwitchTab = (current) => {
setCurrentTab(current)
}
  return (
    <div className='w-100 d-flex justify-content-center align-items-center flex-column'>
       <div className="tabs">
        <button className={currentTab === "form" ? "active_tab" : "dormant_tab"} onClick={() => handleSwitchTab("form")}>{t("createRequest")}</button>
        <button className={currentTab === "favorites" ? "active_tab": "dormant_tab"} onClick={() => handleSwitchTab("favorites")}>{t("saved properties")}</button>
        </div> 
        <div className="w-100 d-flex justify-content-center align-items-center">
        {
 currentTab==="form" ? (
    <MyRequestScreen/>
 ) : (
   <Favorites/>
 )
        }
        </div>
    </div>
  )
}

export default ClientProfile