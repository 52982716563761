import React, { useEffect } from "react";
import "../ForRent/createPost.css";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import {
  Buttons,
  CustomInputField,
  CustomSelectCities,
} from "../../../../../components";
import { phoneNumberInputStyles } from "../../../../../utils/utils";
import { loader } from "../../../../../Assets";

const PropertyLocation = ({
  data,
  inputChange,
 loading,
 error,
 message,
  citiesData,
  phoneNumber,
  handlePhoneInputChange,
  validator,
  prevStep,
  handleAddProperty
}) => {
  const { t } = useTranslation();
  

  useEffect(() => {
    const result = document
      .getElementById("phone__input")
      .getElementsByClassName("special-label");
    result[0].style.display = "none";
  }, []);

  return (
    <div className="createPost__container">
      <form onSubmit={handleAddProperty} className="createPost__form">
        <br />
        <CustomSelectCities
          labelId="city-select"
          selectId="citySelect"
          labelName={t("select city")}
          inputName="city"
          handleInputChange={inputChange}
          value={data?.city}
          validator={validator}
          data={citiesData}
        />
        <CustomInputField
          inputType="text"
          placeholderText={t("quarter")}
          inputName="neighborhood"
          value={data.neighborhood}
          handleInputChange={inputChange}
          validator={validator}
        />
        <CustomInputField
          inputType="number"
          placeholderText={t("calls")}
          inputName="normalCallNumber"
          value={data?.normalCallNumber}
          handleInputChange={inputChange}
        />

        <div className="createPost__input" id="phone__input">
          <PhoneInput
            placeholder="WhatsApp"
            type="text"
            country={"cm"}
            enableAreaCodes={true}
            onlyCountries={["cm"]}
            // areaCodes={{us: ['999']}}
            inputProps={{
              name: "phoneNumber",
              country: "cm",
              required: true,
              autoFocus: true,
            }}
            // containerStyle={{
            //   Padding: "5px",
            // }}
            inputStyle={phoneNumberInputStyles}
            regions={["north-america", "africa", "europe"]}
            value={phoneNumber}
            onChange={handlePhoneInputChange}
            // onChange={props.inputChange}
            // value={props.data.phonNumber}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
          <label className="error text-danger">
            {validator.message(
              "phoneNumber",
              phoneNumber,
              "required|phoneNumber",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="imageUpload__error">
          <span className="text-danger">{error}</span>
        </div>
        {loading && <img src={loader} alt="" className="loading__image" />}
        <div className="submit__container">
          <button
            className={
              message === "upload success" ? "next m-0" : "previous m-0"
            }
          >
            {message === "upload success" ? t("finish") : t("create")}
          </button>
          <Buttons previous={prevStep} />
        </div>
        {error && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default PropertyLocation;
