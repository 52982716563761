import React from "react";
import { useTranslation } from "react-i18next";
import { CustomInputField, CustomSelectField,Buttons, CustomTextArea } from "../../../../../components";
const propertyType = ["land", "house"];
const PropertyInfo = ({
  data,
  inputChange,
  nextStep,
 prevStep,
  validator,

}) => {
  const { t } = useTranslation();

  return (
    <div className="createPost__container">
      <form onSubmit={nextStep} className="createPost__form pt-4">
        <CustomSelectField
          labelId="type-select"
          selectId="typeSelect"
          labelName={t("property type")}
          inputName="type"
          value={data.type}
          handleInputChange={inputChange}
          data={propertyType}
          validator={validator}
        />
        {
          data?.type === "land" && (
            <CustomInputField
          inputType="number"
          inputName="dimension"
          placeholderText="Dimension"
          value={data.dimension}
          handleInputChange={inputChange}
          validator={validator}
        />
          )
        }
        <CustomInputField
          inputType="number"
          inputName="price"
          placeholderText={t("price")}
          value={data.price}
          handleInputChange={inputChange}
          validator={validator}
        />
        <CustomTextArea
          inputName="description"
          placeholderText={t("other details")}
          value={data?.description}
          handleInputChange={inputChange}
        />
        <div className="submit__container">
          <Buttons next={nextStep} previous={prevStep}/>
        </div>
      </form>
    </div>
  );
};

export default PropertyInfo;
