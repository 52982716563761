import React from "react";
import "./createPost.css";
import { useTranslation } from "react-i18next";
import { Buttons } from "../../../../../components";
const FacilitiesSection = ({ inputChange, prevStep, nextStep }) => {
  const { t } = useTranslation();

  return (
    <div className="createPost__container">
      <form onSubmit={nextStep} className="createPost__form">
        <div className="createPost__input">
          <div className="d-flex flex-wrap w-100 justify-content-center align-items-center gap-2">
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("bore hole")}
              <input
                autocomplete="on"
                type="checkbox"
                name="water"
                value="bore hole"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("electricity")}
              <input
                autocomplete="on"
                type="checkbox"
                name="electricity"
                value="electricity"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("wardrobe")}
              <input
                autocomplete="on"
                type="checkbox"
                name="wardrobe"
                value="wardrobe"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("heater")}
              <input
                autocomplete="on"
                type="checkbox"
                name="waterHeater"
                value="heater"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("water")}
              <input
                autocomplete="on"
                type="checkbox"
                name="camWater"
                value="water"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("generator")}
              <input
                autocomplete="on"
                type="checkbox"
                name="generator"
                value="generator"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("interphone")}
              <input
                autocomplete="on"
                type="checkbox"
                name="interphone"
                value="interphone"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("fenced")}
              <input
                autocomplete="on"
                type="checkbox"
                name="fenced"
                value="fenced"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
          </div>
        </div>
        <div className="submit__container">
        <Buttons next={nextStep} previous={prevStep} />
        </div>
      </form>
    </div>
  );
};

export default FacilitiesSection;
