import React, { useEffect, lazy } from "react";
import { useSelector,useDispatch } from "react-redux";
import "./forSale.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainHeader, PaginationContainer } from "../../components";
import { getPaginatedPostsRecord, resetCurrentPage } from "../../redux/actions";

const PostCard = lazy(() =>
  delayForDemo(import("../../components/Cards/PostCard"))
);
const filterOptions = ["houses", "land"];
const ForSaleScreen = ({ posts }) => {
  const { currentPage,sortedSalesPosts,paginatedForSale,numPerPage, numOfPages } = useSelector(
    (state) => state.posts
  );
  const sortedPosts = sortedSalesPosts
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();

    useEffect(() => {
      dispatch(getPaginatedPostsRecord({ currentPage, numPerPage, sortedPosts }));
     
    }, [dispatch,sortedPosts,currentPage,numOfPages,numPerPage]);;
  useEffect(() => {
  if(currentPage > 1 && posts?.length < 30){
    dispatch(resetCurrentPage())
  }
  }, [currentPage,posts,dispatch]);
  
  useEffect(() => {
    if (posts.length === 0 || !posts) {
      navigate("/no-data-fallback");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <main className="forSale__screen" id="top">
      <MainHeader filteredApartments={posts} data={filterOptions} />
      <div className="forSale__postsContainer text-secondary">
        {paginatedForSale?.map((post) => (
          <PostCard item={post} id={post?.id} key={post?.id} />
        ))}
      </div>
      <div className="d-flex justify-content-between align-items-center w-100 px-4">
        <div className="">
          <p className="pagination_note">
            {t("current page")} <span>{currentPage} </span>/{" "}
            <span>{numOfPages}</span>
          </p>
        </div>
        <PaginationContainer />
      </div>
    </main>
  );
};

export default ForSaleScreen;

function delayForDemo(promise) {
  return new Promise((resolve) => {
    setTimeout(resolve, 8000);
  }).then(() => promise);
}
