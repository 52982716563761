import * as actionType from "./constants";

export const postReducer = (
  state = {
    isLoading: false,
    activeMenu:false,
    posts: [],
    totalRentalProperties: 0,
    filteredPosts: [],
    sortedPosts: [],
    sortedSalesPosts: [],
    forSale: [],
    forRent: [],
    favorites: [],
    isSearchLoading: false,
    savedBy: [],
    subscriptionExpiryDateId: null,
    refreshedUser: {},
    subscriptionExpiryDate: null,
    savedToFavorites: null,
    startingCoords: null,
    customResponseMessage: "",
    favoritesButtonTitle: "save property",
    saveNotification: "saved property",
    searchTerm: "",
    forRentRequests: [],
    filterValue: "",
    currentCity: "",
    currency:null,
    geoLocationData: null,
    camGeoData: null,
    currentTab: "houses",
    currentPage: 1,
    numPerPage: 30,
    numOfPages: 1,
    paginatedPosts: [],
    paginatedForSale: [],
    convertedPrice:null,
    allTowns:[]
  },
  action
) => {
  switch (action.type) {
    case actionType.START_LOADING:
      return { ...state, isLoading: true };
    case actionType.TOGGLE_ACTIVE_MENU:
      return { ...state, activeMenu: action.payload };
    case actionType.START_LOADING_SEARCH:
      return { ...state, isSearchLoading: true };
    case actionType.END_LOADING:
      return { ...state, isLoading: false };
    case actionType.GET_ALL_POSTS:
      const totalNumber = action.payload?.length;

      return {
        ...state,
        posts: action.payload,
        totalRentalProperties: totalNumber,
      };
    case actionType.GET_ALL_RENTS_POSTS:
      return { ...state, forRent: action.payload };
    case actionType.SWITCH_PAGE:
      return { ...state, currentPage: action.payload };
    case actionType.SET_ALL_TOWNS:
      return { ...state, allTowns: action.payload };
    case actionType.SET_CONVERTED_PRICE:
      return { ...state, convertedPrice: action.payload };
    case actionType.SET_PAGINATION_DATA:
      const { currentRecords, numOfPages } = action.payload;
      return {
        ...state,
        paginatedPosts: currentRecords,
        numOfPages: numOfPages,
      };
    case actionType.SET_SALE_PAGINATION_DATA:
      const current = action.payload?.currentRecords;
      const numberOfPages = action.payload?.numOfPages;
      return { ...state, paginatedForSale: current, numOfPages: numberOfPages };
    case actionType.GET_ALL_SALE_POSTS:
      return { ...state, forSale: action.payload };
    case actionType.REFRESH_USER:
      return { ...state, refreshedUser: action.payload };
    case actionType.GET_FAVORITES:
      return { ...state, favorites: action.payload };
    case actionType.GET_SAVED_BY:
      return { ...state, savedBy: action.payload };
    case actionType.SAVED_TO_FAVORITES:
      const { title, isSaved, message } = action.payload;
      return {
        ...state,
        savedToFavorites: isSaved,
        favoritesButtonTitle: title,
        saveNotification: message,
      };
    case actionType.REMOVE_FROM_FAVORITES:
      const { title2, isSaved2, message2 } = action.payload;
      return {
        ...state,
        savedToFavorites: isSaved2,
        favoritesButtonTitle: title2,
        saveNotification: message2,
      };
    case actionType.REMOVE_SAVED_BY:
      return { ...state, savedBy: null, savedToFavorites: false };
    case actionType.DELETE_POST:
      return { ...state, posts: action.payload };
    case actionType.SET_SEARCH_TERM:
      return { ...state, filteredPosts: action.payload };
    case actionType.HANDLE_SEARCH_INPUT_CHANGE:
      return { ...state, searchTerm: action.payload };
    case actionType.HANDLE_CLEAR_INPUT:
      return { ...state, searchTerm: action.payload };
    case actionType.SUBSCRIBE_USER:
      return { ...state, isUserSubscribed: true };
    case actionType.UNSUBSCRIBE_USER:
      return { ...state, isUserSubscribed: false };
    case actionType.GET_SUBSCRIPTION_EXPIRY_DATE:
      return { ...state, subscriptionExpiryDate: action.payload };
    case actionType.GET_SUBSCRIPTION_EXPIRY_DATE_ID:
      return { ...state, subscriptionExpiryDateId: action.payload };
    case actionType.SET_CURRENT_COORDS:
      return { ...state, startingCoords: action.payload };
    case actionType.SET_CUSTOM_RESPONSE_MESSAGE:
      return { ...state, customResponseMessage: action.payload };
    case actionType.GET_ALL_REQUESTS:
      return { ...state, forRentRequests: action.payload };
    case actionType.SET_SORTED_POST:
      return { ...state, sortedPosts: action.payload };
    case actionType.SET_SORTED_SALE_POST:
      return { ...state, sortedSalesPosts: action.payload };
    case actionType.SET_TAB:
      return { ...state, currentTab: action.payload };
    case actionType.SET_FILTER_INPUT_VALUE:
      return { ...state, filterValue: action.payload };
    case actionType.SET_CITY_INPUT_VALUE:
      return { ...state, currentCity: action.payload };
    case actionType.GET_GEOLOCATION_DATA:
      return { ...state, geolocationData: action.payload };
    case actionType.GET_CMR_GEOLOCATION_DATA:
      return { ...state, camGeoData: action.payload };
    case actionType.SET_CURRENCY:
      return { ...state, currency: action.payload };
    default:
      return state;
  }
};
