import React, { useState, useEffect } from "react";
import ReactWhatsapp from "react-whatsapp";
import { useTranslation } from "react-i18next";
import { useSelector,useDispatch } from "react-redux";
import { RiWhatsappLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneIcon from '@mui/icons-material/Phone';
import "./postCard.css";
import { deleteRequestAction } from "../../redux/actions";
import { dateConverter } from "../../utils/utils";

const RequestCard = ({ item }) => {
  const [number, setNumber] = useState("237677983166");
 const dispatch = useDispatch()
  const { user } = useSelector((state) => state?.user);
  const { refreshedUser } = useSelector((state) => state.posts);
  const { role } = user || {};
  const { isSubscribed } = refreshedUser || {};
  const { t } = useTranslation();
  const { id, request } = item;
  const date = dateConverter(request?.timestamp)

  useEffect(() => {
    if (request?.phoneNumber !== null) {
      setNumber(request?.phoneNumber);
    }
  }, [request?.phoneNumber]);

const deleteRequest = () => {
dispatch(deleteRequestAction(user?.id,id))
}
  return (
    <div className="request__card">
      <div className="request__card-item-header">
        <h5>{request?.postedBy} {" "} {t(
                "request title"
              )} {" "} {request?.type}{" "}{request?.city}{", "}{request?.neighborhood}</h5>
      </div>
      <div className="request__card-item">
        <h5>{request?.budget}</h5>
      </div>
      <div className="request__card-item">
        <p className="w-100 ">{request?.longDescription}</p>
      </div>

       {role === "agent" && isSubscribed === true ? ( 
        <>
          <div className="request__card-item">
          <PhoneIcon fontSize="medium" color="success" /> <h5>{request?.phoneNumber}</h5>
          </div>
         
            <ReactWhatsapp
              number={number}
              message={` ${t(
                "whatsapp message"
              )} https://homescraper.com/request/${id}`}
              className="request__card-item"
            >
              <div className="whatsappPost__button w-100">
                <RiWhatsappLine color="#06e651" size={25}/>{" "}
                <h5 className="request__card-emphasis">
                  {" "}
                 WhatsApp
                </h5>
                {/* <h5 className="subtitle"> click to message {postedBy}</h5> */}
              </div>
            </ReactWhatsapp>{" "}
      
        </>
       ) : (
        <Link to="/subscribe" className="postDetails__subtitle2">
          {t("see contact")}
        </Link>
      )} 
       <div className="request__card-item mt-2">
        <h5>{date}</h5>
      </div>
     {
        request?.userId === user?.id && (
            <div className="request__card-item-icon">
            <DeleteIcon fontSize="medium" color="error" onClick={deleteRequest}/>
           </div>
        )
     }
     
    </div>
  );
};

export default RequestCard;
