import React from "react";
import { useTranslation } from "react-i18next";

const CustomSelectField = ({
  handleInputChange,
  value,
  data,
  labelId,
  selectId,
  labelName,
  inputName,
  optionValue,
  validator
}) => {
  const { t } = useTranslation();
  return (
    <>
     <div className="custom__input">
      <select
        className="custom__select"
        labelid={labelId}
        id={selectId}
        label={labelName}
        name={inputName}
        onChange={handleInputChange}
        value={value}
        required
      >
        {!data ? (
          <option value={optionValue}>{t(labelName)}</option>
        ) : (
          value === "" && <option value={optionValue}>{t(labelName)}</option>
        )}

        {data?.map((option, index) => {
          return (
            <option key={index} value={option} style={{ color: "#4db5ff" }}>
              {t(option)}
            </option>
          );
        })}
      </select>
    </div>
    <label className="error text-danger w-100 mb-2">
          {validator?.message(`${value}`, value, `required|${value}`, {
            messages: {
              required: t("required"),
            },
          })}
        </label>
    </>
  );
};

export default CustomSelectField;
