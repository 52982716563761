import * as actionType from "./constants";

export const authReducer = (
  state = { user: null, isLoading: false, message: "", locationData: [] },
  action
) => {
  switch (action.type) {
    case actionType.START_AUTH_LOADING:
      return { ...state, isLoading: true };
    case actionType.END_AUTH_LOADING:
      return { ...state, isLoading: false };
    case actionType.LOG_IN_USER:
      return { ...state, user: action.payload };
    case actionType.LOG_OUT_USER:
      return { ...state, user: action.payload };
    case actionType.SET_LOGIN_MESSAGE:
      return { ...state, message: action.payload };
    case actionType.SET_LOCATION_DATA:
      return { ...state, locationData: action.payload };

    default:
      return state;
  }
};
