import * as action from "./constants";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
  //getDocs
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { db, auth, signInWithGooglePopup } from "../firebaseConfig";
import {
  getCurrentSaver,
  getCurrentlySavedData,
  //getCurrentSaverId,
  getSubscriptionExpiryDate,
  getSubscriptionExpiryDateId,
  // eslint-disable-next-line
  refreshUser,
  getSubscriptionExpiryData,
  getCurrentCoordinatesAndLocation,
  convertCurrency,
} from "../utils/utils";
import axios from "axios";

export const getGeolocationDataAction = () => (dispatch) => {
  const fetchGeoData = async () => {
    try {
      const response = await axios.get(
        `https://ipinfo.io?token=${process.env.REACT_APP_IP_INFO_TOKEN}`
      );

      dispatch({ type: action.GET_GEOLOCATION_DATA, payload: response });
    } catch (error) {
      console.error("Error fetching geo data:", error);
    }
  };
  fetchGeoData();
};
export const getCMRGeolocationDataAction = (axios) => (dispatch) => {
  const fetchGeoData = async () => {
    try {
      const response = await axios.get(
        `https://s3.dualstack.us-east-1.amazonaws.com/production-raw-data-api/ISO3/CMR/populated_places/points/hotosm_cmr_populated_places_points_kml.zip`
      );
      dispatch({ type: action.GET_CMR_GEOLOCATION_DATA, payload: response });
    } catch (error) {
      console.error("Error fetching geo data:", error);
    }
  };
  fetchGeoData();
};
export const setConvertedPrice =
  ({ posts, currency }) =>
  async (dispatch) => {
    try {
      posts.forEach(async ({ post }) => {
        console.log(post);
        const convertedPrice = await convertCurrency(
          post?.countryCurrency,
          currency,
          post?.price
        );
        dispatch({ type: action.SET_CONVERTED_PRICE, payload: convertedPrice });
      });
      // const convertedPrice = await convertCurrency(countryCurrency,currency,price)
      // dispatch({ type: action.SET_CONVERTED_PRICE, payload:convertedPrice });
    } catch (error) {
      console.error(error);
    }
  };

export const createPostAction =
  (postData, userId, phoneNumber, urls, navigate) => (dispatch) => {
    dispatch({ type: action.START_LOADING });
    try {
      const {
        type,
        country,
        city,
        neighborhood,
        price,
        fenced,
        category,
        currency,
        numberOfToilets,
        numberAvailable,
        bedrooms,
        dining,
        parking,
      } = postData;

      const { displayName, photoURL } = auth.currentUser;
      const newPost = {
        timestamp: serverTimestamp(),
        imageUrls: urls,
        postedBy: displayName,
        userId: userId,
        type: type,
        city: city,
        fenced: fenced,
        phoneNumber: phoneNumber,
        country: country,
        neighborhood: neighborhood,
        price: price,
        category: category,
        currency: currency,
        bedrooms: bedrooms,
        numberOfToilets: numberOfToilets,
        numberAvailable: numberAvailable,
        dining: dining,
        parking: parking,
        userProfileUrl: photoURL,
      };
      addDoc(collection(db, `/apartments`), newPost);
      dispatch({ type: action.END_LOADING });
      navigate("/");
    } catch (error) {}
  };

// ===========================REMOVE FROM POSTS ======================================

export const deletePostAction = (id, postId) => async (dispatch) => {
  try {
    if (id === auth.currentUser.uid) {
      const postDocRef = doc(db, "apartments", postId);
      await deleteDoc(postDocRef);

      dispatch({ type: action.DELETE_POST, payload: postId });
    }
  } catch (error) {
    console.log(error);
  }
};
// ===========================REMOVE FROM POSTS ======================================

export const deleteOverduePostAction = (postId) => async (dispatch) => {
  try {
    const postDocRef = doc(db, "apartments", postId);
    await deleteDoc(postDocRef);
    dispatch({ type: action.DELETE_POST, payload: postId });
  } catch (error) {
    console.log(error);
  }
};
export const getAllPosts =
  ({
    currentTown,
    navigate,
    country,
    currentStateCode,
    currentCity,
    currency,
  }) =>
  async (dispatch) => {
    //let countryCurrency;
    try {
      if (currency) {
        dispatch({ type: action.START_LOADING });
        // const currentCurrency = currency?.toUpperCase();
        // // const postCurrency = !countryCurrency?.toUpperCase() ?  "XAF" : countryCurrency?.toUpperCase()

        // const url = `https://exchange-rates7.p.rapidapi.com/latest?base=NGN`;
        // const options = {
        //   method: "GET",
        //   headers: {
        //     "x-rapidapi-key":
        //       "ae5c8a4bf1msha6fa0da127be674p1c54f9jsn28fe7ae34dac",
        //     "x-rapidapi-host": "exchange-rates7.p.rapidapi.com",
        //   },
        // };

        // const response = await fetch(url, options);
        // const result = await response.json();
        // const info = result?.rates;
        // console.log(result?.rates);
        // const rate = info[currentCurrency];
        // console.log(rate);
        // let newPrice = await Math.round(price * rate )

        const data = query(
          collection(db, "apartments"),
          orderBy("timestamp", "desc")
        );

        if (currentTown) {
          const getData = async () => {
            await onSnapshot(data, (querySnapshot) => {
              const newData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                post: doc.data(),
              }));

              const filterTown = currentCity === "" ? currentTown : currentCity;
              // const convertedPosts = newData?.map(({ post }) =>
              //   Math.round(post?.price * rate)
              // );

              const allTowns = [];
              // eslint-disable-next-line array-callback-return
              newData.map(({ post }) => {
                if (allTowns.indexOf(post?.city) === -1) {
                  allTowns.push(post.city);
                }
              });

              dispatch({ type: action.SET_ALL_TOWNS, payload: allTowns });
              const allForRent = newData.filter(({ post }) =>
                post?.category?.toLowerCase().match("for rent")
              );
              const allForSale = newData.filter(({ post }) =>
                post?.category?.toLowerCase().match("for sale")
              );
              const forRent = allForRent.filter(({ post }) =>
                post?.city?.toLowerCase().match(filterTown?.toLowerCase())
              );
              // const forSale = allForSale.filter(({ post }) =>
              //   post?.city?.toLowerCase().match(currentTown?.toLowerCase())
              // );
              // const forSale = allForSale.filter(({ post }) =>
              //   post?.city?.toLowerCase().match(currentTown?.toLowerCase())
              // );

              // const forRent = newData.filter(({ post }) =>
              //   post?.category?.toLowerCase().match("for rent") && post?.stateCode?.toLowerCase().match(currentStateCode?.toLowerCase()));
              // const forSale = newData.filter(({ post }) =>
              //   post?.category?.toLowerCase().match("for sale") && post?.stateCode?.toLowerCase().match(currentStateCode?.toLowerCase()));

              // if (currentTown.match("Douala")) {
              //   const rentals = allForRent.filter(
              //     ({ post }) =>
              //      post?.city?.toLowerCase().match(filterTown?.toLowerCase())
              //   );
              //   // const sale = allForSale.filter(
              //   //   ({ post }) =>
              //   //     post?.city?.match("Douala") || post?.city.match("Bonabéri")
              //   // );

              //   dispatch({ type: action.GET_ALL_POSTS, payload: newData });
              //   dispatch({ type: action.GET_ALL_SALE_POSTS, payload: allForSale });
              //   dispatch({
              //     type: action.GET_ALL_RENTS_POSTS,
              //     payload: rentals,
              //   });
              // } else

              if (
                country.match("CM") &&
                forRent?.length === 0
                // ||
                // (country.match("CM") && forSale?.length === 0)
              ) {
                dispatch({ type: action.GET_ALL_POSTS, payload: newData });
                dispatch({
                  type: action.GET_ALL_SALE_POSTS,
                  payload: allForSale,
                });
                dispatch({
                  type: action.GET_ALL_RENTS_POSTS,
                  payload: allForRent,
                });
              } else if (
                country.match("CM") &&
                forRent?.length > 0
                // ||
                // (country.match("CM") && forSale?.length > 0)
              ) {
                dispatch({ type: action.GET_ALL_POSTS, payload: newData });
                dispatch({
                  type: action.GET_ALL_SALE_POSTS,
                  payload: allForSale,
                });
                dispatch({
                  type: action.GET_ALL_RENTS_POSTS,
                  payload: forRent,
                });
              } else {
                const forRent = newData.filter(({ post }) =>
                  post?.category?.toLowerCase().match("for rent")
                );
                const forSale = newData.filter(({ post }) =>
                  post?.category?.toLowerCase().match("for sale")
                );

                dispatch({ type: action.GET_ALL_POSTS, payload: newData });
                dispatch({ type: action.GET_ALL_SALE_POSTS, payload: forSale });
                dispatch({
                  type: action.GET_ALL_RENTS_POSTS,
                  payload: forRent,
                });
              }
            });
          };
          getData();
          dispatch({ type: action.END_LOADING });
        }
      }
    } catch (error) {
      console.log(error);
      navigate("/network-error");
    }
  };
export const getAllRequests = () => async (dispatch) => {
  try {
    dispatch({ type: action.START_LOADING });
    const data = query(
      collection(db, "clientRequests"),
      orderBy("timestamp", "desc")
    );

    const getData = async () => {
      await onSnapshot(data, (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          request: doc.data(),
        }));

        dispatch({ type: action.GET_ALL_REQUESTS, payload: newData });
      });
    };
    getData();
    dispatch({ type: action.END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// ===========================REMOVE FROM POSTS ======================================

export const deleteRequestAction = (id, postId) => async (dispatch) => {
  try {
    if (id === auth.currentUser.uid) {
      const postDocRef = doc(db, "clientRequests", postId);
      await deleteDoc(postDocRef);

      dispatch({ type: action.DELETE_POST, payload: postId });
    }
  } catch (error) {
    console.log(error);
  }
};

export const switchTabAction = (current) => async (dispatch) => {
  try {
    dispatch({ type: action.SET_TAB, payload: current });
  } catch (error) {
    console.log(error);
  }
};
export const setCurrencyAction = (currency) => async (dispatch) => {
  try {
    dispatch({ type: action.SET_CURRENCY, payload: currency });
  } catch (error) {
    console.log(error);
  }
};
export const clearFilterInput = () => async (dispatch) => {
  try {
    dispatch({ type: action.SET_FILTER_INPUT_VALUE, payload: "" });
    dispatch({ type: action.SET_CITY_INPUT_VALUE, payload: "" });
  } catch (error) {
    console.log(error);
  }
};

export const handleFilterInputChange =
  ({ e, posts, setCustomResponseMessage }) =>
  async (dispatch) => {
    const sortedPosts = posts.toSorted(function (a, b) {
      if (a?.post?.neighborhood < b?.post?.neighborhood) {
        return -1;
      }
      if (a?.post?.neighborhood > b?.post?.neighborhood) {
        return 1;
      }
      return 0;
    });

    try {
      const value = e.target.value;
      if (value === "") {
        dispatch({ type: action.SET_SORTED_POST, payload: posts });
        dispatch({ type: action.SET_FILTER_INPUT_VALUE, payload: value });
      } else if (value.match("a-z")) {
        dispatch({ type: action.SET_SORTED_POST, payload: sortedPosts });
        dispatch({ type: action.SET_FILTER_INPUT_VALUE, payload: value });
      } else {
        const newPosts = posts?.filter(({ post }) =>
          post?.type?.toLowerCase().match(value.toLowerCase())
        );
        if (newPosts?.length > 0 && value !== "") {
          dispatch({ type: action.SET_SORTED_POST, payload: newPosts });
        } else {
          dispatch(
            setCustomResponseMessage(
              `ooppsss! there are ${newPosts.length}  ${value}`
            )
          );
        }
        dispatch({ type: action.SET_FILTER_INPUT_VALUE, payload: value });
      }
    } catch (error) {
      console.log(error);
    }
  };
export const handleCityInputChange =
  ({ e, sortedPosts }) =>
  async (dispatch) => {
    const currentCity = e.target.value;

    try {
      if (currentCity === "") {
        console.log("true");
        dispatch({ type: action.SET_SORTED_POST, payload: sortedPosts });
        dispatch({ type: action.SET_CITY_INPUT_VALUE, payload: currentCity });
      }
      const newPosts = sortedPosts?.filter(({ post }) =>
        post?.city?.toLowerCase().match(currentCity.toLowerCase())
      );
      if (newPosts?.length > 0) {
        dispatch({ type: action.SET_SORTED_POST, payload: newPosts });
      }
      dispatch({ type: action.SET_CITY_INPUT_VALUE, payload: currentCity });
    } catch (error) {
      console.log(error);
    }
  };

export const getPaginatedPostsRecord =
  ({ currentPage, numPerPage, sortedPosts }) =>
  async (dispatch) => {
    try {
      const indexOfLastRecord = currentPage * numPerPage;
      const indexOfFirstRecord = indexOfLastRecord - numPerPage;
      const currentRecords = sortedPosts?.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      const numOfPages = Math.ceil(sortedPosts?.length / numPerPage);
      dispatch({
        type: action.SET_PAGINATION_DATA,
        payload: { currentRecords, numOfPages },
      });
      dispatch({
        type: action.SET_SALE_PAGINATION_DATA,
        payload: { currentRecords, numOfPages },
      });
    } catch (error) {
      console.log(error);
    }
  };
export const resetCurrentPage = () => async (dispatch) => {
  if (
    window.location.pathname === "/" ||
    window.location.pathname === "for-sale"
  ) {
    const newPage = 1;
    dispatch({ type: action.SWITCH_PAGE, payload: newPage });
  }
};
export const switchToNextPage =
  ({ currentPage, numOfPages }) =>
  async (dispatch) => {
    if (currentPage !== numOfPages) {
      const newPage = currentPage + 1;
      dispatch({ type: action.SWITCH_PAGE, payload: newPage });
    }
  };

export const switchToPreviousPage = (currentPage) => async (dispatch) => {
  if (currentPage !== 1) {
    const newPage = currentPage - 1;
    dispatch({ type: action.SWITCH_PAGE, payload: newPage });
  }
};
export const setSortedPostsAction = (post) => async (dispatch) => {
  dispatch({ type: action.SET_SORTED_POST, payload: post });
};
export const setForSaleSortedAction = (post) => async (dispatch) => {
  dispatch({ type: action.SET_SORTED_SALE_POST, payload: post });
};

export const getFavoritesAction = (userId) => async (dispatch) => {
  const data = query(
    collection(db, `/favorites/${userId}/clientFavorites`),
    orderBy("timestamp", "desc")
  );
  const currentFavorites = await getCurrentlySavedData(data);
  // console.log(currentFavorites);

  dispatch({ type: action.GET_FAVORITES, payload: currentFavorites });
};

// ===========================SEARCH ACTIONS======================================

export const searchAction =
  ({
    posts,
    searchTerm,
    //today, expiryDate,
    navigate,
  }) =>
  (dispatch) => {
    // console.log('search term actions',searchTerm)
    // console.log('actions posts',posts)

    try {
      dispatch({ type: action.START_LOADING_SEARCH });
      // if (!expiryDate || today > expiryDate) {
      //   navigate("/subscribe");
      // }

      const filtered = posts.filter(
        ({ post }) =>
          post?.city?.toLowerCase().match(searchTerm.toLowerCase()) ||
          post?.neighborhood?.toLowerCase().match(searchTerm.toLowerCase())
        // for offline testing
        // post?.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // post?.neighborhood
        //   ?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      // console.log(filtered);
      if (searchTerm !== "" && filtered.length > 0) {
        navigate("/searched-properties");
      } else if (searchTerm !== "" && filtered.length === 0) {
        navigate("/no-data-fallback");
      }
      dispatch({ type: action.SET_SEARCH_TERM, payload: filtered });
      dispatch({ type: action.END_LOADING });
    } catch (error) {}
  };
export const setSearchTerm = (e) => (dispatch) => {
  // console.log('search term actions',searchTerm)
  // console.log('actions posts',posts)

  try {
    const value = e.target.value;
    console.log(value);
    dispatch({ type: action.HANDLE_SEARCH_INPUT_CHANGE, payload: value });
  } catch (error) {}
};
export const clearSearchTerm = () => (dispatch) => {
  try {
    const value = "";
    dispatch({ type: action.HANDLE_CLEAR_INPUT, payload: value });
  } catch (error) {}
};

// ===========================REMOVE FROM FAVORITES======================================

export const removeFromFavoritesAction =
  (userId, postId, likes) => async (dispatch) => {
    try {
      ///  const likedByDocRef = doc(db, `/favorites/${postId}/savedBy`, postId);
      const postDocRef = doc(
        db,
        `/favorites/${userId}/clientFavorites`,
        postId
      );
      //const taskDocRef = doc(db, "apartments", postId);
      //updateDoc(taskDocRef, auth.currentUser.uid, { likes: likes - 1 });
      //await deleteDoc(likedByDocRef);
      await deleteDoc(postDocRef);
      dispatch({
        type: action.REMOVE_FROM_FAVORITES,
        payload: "save property",
      });
      const data = query(collection(db, `/favorites/${postId}/savedBy`));
      const getData = async () => {
        await onSnapshot(data, (querySnapshot) => {
          const snapshot = querySnapshot.docs.map((doc) => doc.data());
          const newData = snapshot?.map(({ likedBy }) => likedBy);
          console.log(newData);
          dispatch({ type: action.GET_SAVED_BY, payload: newData });
        });
      };
      getData();
    } catch (error) {
      console.log(error);
    }
  };

// ===========================TOGGLE ADD TO FAVORITES======================================

export const toggleSaveFavoritesAction =
  (item, postId, userId) => async (dispatch) => {
    localStorage.clear();
    const {
      type,
      country,
      city,
      neighborhood,
      price,
      category,
      currency,
      numberOfToilets,
      numberAvailable,
      bedrooms,
      dining,
      parking,
      phoneNumber,
      fenced,
      imageUrls,
      timestamp,
      likes,
    } = item;
    const data = query(
      collection(db, `/favorites/${userId}/clientFavorites`),
      orderBy("timestamp", "desc")
    );
    const currentFavorites = await getCurrentlySavedData(data);
    const currentData = query(collection(db, `/favorites/${postId}/savedBy`));
    const currentlySavedBy = await getCurrentlySavedData(currentData);
    const currentSaver = getCurrentSaver(currentlySavedBy, userId);

    console.log(userId);
    currentFavorites.forEach(({ item }) => {
      if (item?.id === postId) {
        dispatch({
          type: action.SAVED_TO_FAVORITES,
          payload: {
            title: "save property",
            isSaved: true,
            message: "already saved",
          },
        });
      }
    });

    dispatch({ type: action.GET_SAVED_BY, payload: currentSaver });

    if (!currentSaver[0]) {
      console.log("not yet saved by me, saving now...");
      const newLikes = likes + 1;
      // console.log(currentlySavedBy, userId, postId);
      const colRefUser = collection(db, `/favorites/${postId}/savedBy`);
      addDoc(colRefUser, {
        savedBy: userId,
      });
      const colRef = collection(db, `/favorites/${userId}/clientFavorites`);
      const taskDocRef = doc(db, "apartments", postId);
      updateDoc(taskDocRef, {
        likes: newLikes,
      });
      addDoc(colRef, {
        imageUrls: imageUrls,
        type: type,
        city: city,
        phoneNumber: phoneNumber,
        country: country,
        neighborhood: neighborhood,
        price: price,
        fenced: fenced,
        category: category,
        currency: currency,
        bedrooms: bedrooms,
        numberOfToilets: numberOfToilets,
        numberAvailable: numberAvailable,
        dining: dining,
        parking: parking,
        timestamp: timestamp,
        id: postId,
      });

      const data = query(
        collection(db, `/favorites/${userId}/clientFavorites`),
        orderBy("timestamp", "desc")
      );
      const currentFavorites = await getCurrentlySavedData(data);
      console.log(currentFavorites);
      dispatch({ type: action.GET_FAVORITES, payload: currentFavorites });
    }
    dispatch({
      type: action.SAVED_TO_FAVORITES,
      payload: {
        title: "saved property",
        isSaved: true,
        message: "saved property",
      },
    });
    console.log(currentFavorites);
  };

// =======================GET FAVORITES  SAVED BY USER======================================

export const checkIfSavedToFavoritesAction =
  ({ currentUserId, postId }) =>
  async (dispatch) => {
    const colRef = query(
      collection(db, `/favorites/${currentUserId}/clientFavorites`)
    );
    const newData = await getCurrentlySavedData(colRef);
    if (newData.length === 0) return;
    console.log(newData);
    newData.forEach(({ item }) =>
      item?.id === postId
        ? dispatch({
            type: action.SAVED_TO_FAVORITES,
            payload: {
              title: "saved property",
              isSaved: true,
              message: "saved property",
            },
          })
        : dispatch({
            type: action.REMOVE_FROM_FAVORITES,
            payload: {
              title2: "save property",
              isSaved2: false,
              message2: "save property",
            },
          })
    );

    // dispatch({ type: action.SAVED_TO_FAVORITES ,payload:"saved property"});
  };
export const getUsersWhoSavedToFavoritesAction =
  (postId, userId) => async (dispatch) => {
    const data = query(collection(db, `/favorites/${postId}/savedBy`));

    const newData = await getCurrentlySavedData(data);
    //console.log(newData)

    const savedBy = getCurrentSaver(newData, userId, postId);

    //console.log(savedBy)
    // const itemId = getCurrentSaverId(newData,userId);
    //console.log(itemId)

    dispatch({ type: action.GET_SAVED_BY, payload: savedBy[1] });
    // dispatch({ type: action.GET_SAVED_BY_ID, payload: itemId })
  };

// ===========================AUTHENTICATION ACTIONS======================================

// ===========================REGISTER ACTIONS======================================

export const RegisterAction = (formData, navigate) => (dispatch) => {
  dispatch({ type: action.START_AUTH_LOADING });

  const {
    username,
    email,
    role,
    city,
    country,
    stateCode,
    password,
    phoneNumber,
    isSubscribed,
    isTrialDone,
  } = formData;
  const colRef = collection(db, "users");

  try {
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        setDoc(doc(colRef, auth.currentUser.uid), {
          joinedOn: serverTimestamp(),
          displayName: username,
          phoneNumber: phoneNumber,
          country: country,
          stateCode: stateCode,
          city: city,
          role: role,
          email: email,
          isSubscribed: isSubscribed,
          isTrialDone: isTrialDone,
          id: auth.currentUser.uid,
        }).then(() => {
          sendEmailVerification(auth.currentUser).then(() => {
            auth.useDeviceLanguage();
          });
          const { emailVerified, email } = auth.currentUser;

          if (email && emailVerified === false) {
            navigate("/verify-email");
          }

          dispatch({ type: action.END_AUTH_LOADING });
        });
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            console.log(`Email address ${email} already in use.`);
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload: `Email address ${email} already in use.`,
            });
            break;
          case "auth/invalid-email":
            console.log(`Email address ${email} is invalid.`);
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload: `Email address ${email} is invalid.`,
            });
            break;
          case "auth/operation-not-allowed":
            console.log(`Error during sign up.`);
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload: "Error during sign up.",
            });
            break;
          case "auth/weak-password":
            console.log(
              "Password is not strong enough. Add additional characters including special characters and numbers."
            );
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload:
                "Password is not strong enough. Add additional characters including special characters and numbers.",
            });
            break;
          default:
            console.log(error.message);
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload: error.message,
            });
            break;
        }
      });
  } catch (error) {
    console.log(error);

    dispatch({ type: action.END_AUTH_LOADING });
  }
};
// ===========================SIGN IN ACTION======================================

export const signInAction = (formData, navigate) => (dispatch) => {
  try {
    dispatch({ type: action.START_AUTH_LOADING });
    const { email, password } = formData;
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        const colRef = collection(db, "users");
        getDoc(doc(colRef, auth.currentUser?.uid)).then((snapshot) => {
          const { isSubscribed } = snapshot.data();
          dispatch({ type: action.LOG_IN_USER, payload: snapshot.data() });
          dispatch({ type: action.END_AUTH_LOADING });
          if (auth.currentUser.emailVerified === false) {
            navigate(`/verify-email`);
          } else if (isSubscribed === false) {
            navigate(`/subscribe`);
          } else {
            navigate(`/`);
          }
        });
        // ...
      })
      .catch((error) => {
        console.log(error.code);
        dispatch({ type: action.END_AUTH_LOADING });
        switch (error.code) {
          case "auth/email-already-in-use":
            console.log(`Email address ${email} already in use.`);
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload: `Email address ${email} already in use.`,
            });
            break;
          case "auth/invalid-email":
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload: `Email address ${email} is invalid.`,
            });
            break;
          case "auth/wrong-password":
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload:
                "The password is invalid or the user does not have a password.",
            });
            break;
          case "auth/unverified-email":
            console.log("The operation requires a verified email.");
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload: "The operation requires a verified email.",
            });
            break;
          case "auth/network-request-failed":
            console.log(
              "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred."
            );
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload:
                "A network Error (such as timeout, interrupted connection or unreachable host) has occurred.",
            });
            break;
          default:
            console.log(error.message);
            dispatch({
              type: action.SET_LOGIN_MESSAGE,
              payload: error.message,
            });
            break;
        }
      })
      .catch((error) => {
        console.log(error.code);

        dispatch({
          type: action.SET_LOGIN_MESSAGE,
          payload: "please check your login credentials or network connection",
        });
        dispatch({ type: action.END_AUTH_LOADING });
      });
  } catch (error) {
    console.log(error.code);
  }
};
export const googleAuthSignInAction = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: action.START_AUTH_LOADING });
    const response = await signInWithGooglePopup();

    const { email, emailVerified } = response?.user;
    if (email && emailVerified === true) {
      const colRef = collection(db, "users");
      getDoc(doc(colRef, auth.currentUser?.uid)).then((snapshot) => {
        //const name = snapshot.data().displayName;
        dispatch({ type: action.LOG_IN_USER, payload: snapshot.data() });
        dispatch({ type: action.END_AUTH_LOADING });
        navigate(`/`);
      });
    } else {
      dispatch({
        type: action.SET_LOGIN_MESSAGE,
        payload: "please check your login credentials or network connection",
      });
      dispatch({ type: action.END_LOADING });
    }
  } catch (error) {
    dispatch({
      type: action.SET_LOGIN_MESSAGE,
      payload: error.code,
    });
  }
};

// ===========================SIGN OUT ACTION======================================
export const singOutAction = (navigate) => (dispatch) => {
  try {
    localStorage.clear();
    signOut(auth);
    window.location.reload();
    dispatch({ type: action.LOG_OUT_USER, payload: "" });
    navigate("/");
  } catch (error) {}
};
export const clearMessageAction = () => (dispatch) => {
  try {
    dispatch({
      type: action.SET_LOGIN_MESSAGE,
      payload: "",
    });
  } catch (error) {}
};
export const toggleMobileNavbar = (activeMenu) => (dispatch) => {
  try {
    dispatch({
      type: action.TOGGLE_ACTIVE_MENU,
      payload: !activeMenu,
    });
  } catch (error) {}
};
// ===========================SUBSCRIPTION ACTION======================================
export const monthlySubscriptionAction =
  (isSubscribed, isTrialDone, subscriptionExpiryDateId, role, navigate) =>
  async (dispatch) => {
    const userId = auth.currentUser.uid;
    try {
      dispatch({ type: action.START_LOADING });
      const taskDocRef = doc(db, "users", userId);
      const today = new Date();
      const newSubscriptionExpiryDate = new Date(
        role === "agent"
          ? today.getTime() + 180 * 24 * 60 * 60 * 1000
          : today.getTime() + 30 * 24 * 60 * 60 * 1000
      );

      if (isSubscribed === false && isTrialDone === false) {
        console.log("first time subscriber");
        updateDoc(taskDocRef, {
          isSubscribed: true,
          isTrialDone: true,
        }).then(async () => {
          const colRef = collection(
            db,
            `users/${userId}/subscriptionExpiryDate`
          );
          addDoc(colRef, {
            expiryDate: newSubscriptionExpiryDate,
          });
          const newData = await getSubscriptionExpiryData(userId);
          const expiryDate = getSubscriptionExpiryDate(newData);
          dispatch({
            type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
            payload: expiryDate,
          });
          const user = await refreshUser();
          dispatch({ type: action.REFRESH_USER, payload: user });
          dispatch({ type: action.SUBSCRIBE_USER });
          dispatch({ type: action.END_LOADING });
          navigate(`/`);
        });
      } else if (isSubscribed === false && isTrialDone === true) {
        console.log("second time subscriber");
        const expiryTaskDocRef = doc(
          db,
          `users/${userId}/subscriptionExpiryDate`,
          subscriptionExpiryDateId
        );
        updateDoc(taskDocRef, {
          isSubscribed: true,
        }).then(async () => {
          updateDoc(expiryTaskDocRef, {
            expiryDate: newSubscriptionExpiryDate,
          });
          const newData = await getSubscriptionExpiryData(userId);
          const expiryDate = getSubscriptionExpiryDate(newData);
          dispatch({
            type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
            payload: expiryDate,
          });
          const user = await refreshUser();
          dispatch({ type: action.REFRESH_USER, payload: user });
          dispatch({
            type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
            payload: expiryDate,
          });
          dispatch({ type: action.SUBSCRIBE_USER });
          dispatch({ type: action.END_LOADING });
          navigate(`/`);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
export const automaticSubscriptionRenew =
  (isSubscribed, isTrialDone, subscriptionExpiryDateId, role, navigate) =>
  async (dispatch) => {
    const userId = auth.currentUser.uid;
    try {
      dispatch({ type: action.START_LOADING });
      const taskDocRef = doc(db, "users", userId);
      const today = new Date();
      const newSubscriptionExpiryDate = new Date(
        role === "agent"
          ? today.getTime() + 180 * 24 * 60 * 60 * 1000
          : today.getTime() + 30 * 24 * 60 * 60 * 1000
      );

      if (isSubscribed === false && isTrialDone === true) {
        console.log("second time subscriber");
        updateDoc(taskDocRef, {
          isSubscribed: true,
        }).then(async () => {
          const colRef = collection(
            db,
            `users/${userId}/subscriptionExpiryDate`
          );
          addDoc(colRef, {
            expiryDate: newSubscriptionExpiryDate,
          });
          const newData = await getSubscriptionExpiryData(userId);
          const expiryDate = getSubscriptionExpiryDate(newData);
          dispatch({
            type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
            payload: expiryDate,
          });
          const user = await refreshUser();
          dispatch({ type: action.REFRESH_USER, payload: user });
          dispatch({ type: action.SUBSCRIBE_USER });
          dispatch({ type: action.END_LOADING });
          navigate(`/`);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
export const unSubscriptionAction =
  (userId, isSubscribed, navigate) => async (dispatch) => {
    const today = new Date();
    // const newSubscriptionExpiryDate = new Date(
    //   today.getTime() + 1 * 24 * 60 * 60 * 1000
    // );

    const newData = await getSubscriptionExpiryData(userId);
    dispatch({ type: action.START_LOADING });
    const expiryDate = getSubscriptionExpiryDate(newData);
    dispatch({
      type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
      payload: expiryDate,
    });
    const expiryDateId = getSubscriptionExpiryDateId(newData);

    //console.log(expiryDate, expiryDateId)
    dispatch({
      type: action.GET_SUBSCRIPTION_EXPIRY_DATE_ID,
      payload: expiryDateId,
    });
    // const expiryTaskDocRef = doc(
    //   db,
    //   `users/${userId}/subscriptionExpiryDate`,
    // expiryDateId
    // );

    const taskDocRef = doc(db, "users", userId);

    if (isSubscribed === true && today >= expiryDate) {
      updateDoc(taskDocRef, {
        isSubscribed: false,
      });
      // .then(async() => {
      //   updateDoc(expiryTaskDocRef, {
      //     expiryDate:newSubscriptionExpiryDate,
      //   })
      // })
      const newData = await getSubscriptionExpiryData(userId);
      const expiryDate = getSubscriptionExpiryDate(newData);
      dispatch({
        type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
        payload: expiryDate,
      });
      const expiryDateId = getSubscriptionExpiryDateId(newData);
      dispatch({
        type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
        payload: expiryDate,
      });
      const user = await refreshUser();
      dispatch({ type: action.REFRESH_USER, payload: user });
      // console.log(expiryDate, expiryDateId)
      dispatch({
        type: action.GET_SUBSCRIPTION_EXPIRY_DATE_ID,
        payload: expiryDateId,
      });
      navigate("/");
    }
  };

export const getSubscriptionExpiryDataAction = (userId) => async (dispatch) => {
  dispatch({ type: action.START_LOADING });
  const newData = await getSubscriptionExpiryData(userId);
  const expiryDate = getSubscriptionExpiryDate(newData);
  const expiryDateId = getSubscriptionExpiryDateId(newData);

  dispatch({ type: action.GET_SUBSCRIPTION_EXPIRY_DATE, payload: expiryDate });
  // console.log(user)
  dispatch({
    type: action.GET_SUBSCRIPTION_EXPIRY_DATE_ID,
    payload: expiryDateId,
  });
  const user = await refreshUser();
  dispatch({ type: action.REFRESH_USER, payload: user });
  dispatch({ type: action.END_LOADING });
};
export const getCurrentLocationCoordsAction =
  (startingPoint, startingNeighborhood) => async (dispatch) => {
    dispatch({
      type: action.SET_CURRENT_COORDS,
      payload: { startingPoint, startingNeighborhood },
    });
  };

// ===========================SET CUSTOM RESPONSE MESSAGE ACTIONS======================================
export const setCustomResponseMessage = (message) => async (dispatch) => {
  console.log(message);
  dispatch({ type: action.SET_CUSTOM_RESPONSE_MESSAGE, payload: message });
};
// ===========================SET LOCATION DATA ACTIONS======================================
export const setLocationData = (latitude, longitude) => async (dispatch) => {
  const getData = async () => {
    const data = await getCurrentCoordinatesAndLocation(latitude, longitude);
    // const data = await getCurrentCoordinatesAndLocation(yaoundeLat, yaoundeLong)
    return data;
  };
  const data = await getData();
  dispatch({ type: action.SET_LOCATION_DATA, payload: data });
};
