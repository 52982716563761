import React from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

const ImageSlider = ({ images,indicators,arrows }) => {
  const { t } = useTranslation();

  return (
    <div className="imageSlider-container">
      <Carousel
        className="carousel"
        axis="horizontal"
        dynamicHeight={true}
        showArrows={arrows ? arrows : false}
        showIndicators={indicators ? indicators : false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        centerMode={true}
       centerSlidePercentage={110}
       preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
      >
        {images?.map((image, index) => (
          <div className="imageSlider__images" key={index}>
            <img src={image} alt="details slider"/>
          </div>
        ))}
      </Carousel>
      {
       ( window.location.pathname === "/" ||  window.location.pathname === "/for-sale") && (
        <div className="header__body">
        <div className="flex flex-col justify-content-center align-items-center w-100 ">
        <h1 className="header-text">
          {t("welcome one")} <span className="website__name-header">HOMESCRAPER.</span>
        </h1>
        <h1 className="header-text">
          {t('welcome two')}
        </h1>
        </div>
        <div className="flex flex-col justify-content-center align-items-center w-100 pt-3">
          <div className="slider__links">
            <Link to="/for-sale" className=" btn-primary category__link">
              {t("sale button")}
            </Link>
            <Link to="/" className=" btn-primary category__link">
              {t("rent button")}
            </Link>
          </div>
        </div>
      </div>
       )
      }
    </div>
  );
};

export default ImageSlider;
