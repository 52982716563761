import React, { useState, useEffect, Suspense, useContext } from "react";
import { loader } from "../../Assets";
import "./searched.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//import Pagination from "./paginate";
import { useSelector, useDispatch } from "react-redux";
import { DataFilter, PostCard } from "../../components";
import { finalDateConverter, today } from "../../utils/utils";
import { Link } from "react-router-dom";
import { IPInfoContext } from "ip-info-react";
import { setCustomResponseMessage, setLocationData } from "../../redux/actions";

const SearchedScreen = ({ currentLocation }) => {
  const { city, suburb } = currentLocation || {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSearchLoading } = useSelector((state) => state.posts);
  const [filtered, setFiltered] = useState([]);
  const {
    subscriptionExpiryDate,
    filteredPosts,
    searchTerm,
    filterValue,
    currentCity,
  } = useSelector((state) => state.posts);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const currentUserId = user?.id;
  const finalDate = finalDateConverter(subscriptionExpiryDate);
  const locationInfo = useContext(IPInfoContext);

  useEffect(() => {
    if (!filterValue && !currentCity) {
      setFiltered(filteredPosts);
    } else if (filterValue || currentCity) {
      setFiltered(filteredPosts);
    }
    // console.log(posts)
  }, [filteredPosts, currentCity, filterValue, dispatch]);

  useEffect(() => {
    try {
      dispatch(setLocationData(locationInfo.latitude, locationInfo.longitude));
    } catch (error) {
      console.log(error);
    }
  }, [locationInfo.latitude, locationInfo.longitude, dispatch]);

  useEffect(() => {
    if (filtered.length > 0) {
      const newPosts = filtered?.filter(({ post }) =>
        post?.type?.toLowerCase().match(filterValue.toLowerCase())
      );
      if (newPosts?.length > 0 && filterValue) {
        setFiltered(newPosts);
        //setValue("")
      } else if (newPosts?.length <= 0 && filterValue) {
        dispatch(
          setCustomResponseMessage(
            `ooppsss! there are ${newPosts.length} ${filterValue}`
          )
        );
      }
    } else {
      const newPosts = filteredPosts?.filter(({ post }) =>
        post?.type?.toLowerCase().match(filterValue.toLowerCase())
      );
      if (newPosts?.length > 0) {
        setFiltered(newPosts);
        //setValue("")
      }
    }
  }, [filterValue, filteredPosts, filtered, currentCity, dispatch]);

  useEffect(() => {
    if (filtered.length > 0) {
      const newPosts = filtered?.filter(({ post }) =>
        post?.city.toLowerCase()?.match(currentCity.toLowerCase())
      );
      if (newPosts?.length > 0 && currentCity) {
        setFiltered(newPosts);
        //setValue("")
      } else if (newPosts?.length <= 0 && currentCity) {
        dispatch(
          setCustomResponseMessage(
            `ooppsss! there are ${newPosts.length} properties in ${currentCity}`
          )
        );
      }
    } else {
      const newPosts = filteredPosts?.filter(({ post }) =>
        post?.city?.toLowerCase().match(currentCity.toLowerCase())
      );
      if (newPosts?.length > 0) {
        setFiltered(newPosts);
      }
    }
  }, [currentCity, filteredPosts, filtered, dispatch, filterValue]);

  useEffect(() => {
    if (searchTerm !== "" && filteredPosts.length > 59) {
      console.log(filteredPosts);
      console.log(currentLocation);
    }
  }, [searchTerm, filteredPosts, navigate, currentLocation]);

  return (
    <main className="home__screen" id="top">
      <div className="subscription_notice">
        <h4 className="totalRentalProperties">
          {filteredPosts?.length} {t("available properties")}{" "}
          {searchTerm ? searchTerm : null}
        </h4>
        {/* <h4 className="totalRentalProperties">
          Latitude:{locationInfo.latitude} : Longitude:{locationInfo.longitude}
        </h4> */}
        {subscriptionExpiryDate && currentUserId ? (
          <div className="home__expiry-date">
            {today > subscriptionExpiryDate ? (
              <p className="home__expired-date">
                {t("expired subscription")} {finalDate}
              </p>
            ) : (
              <p className="home__expire-soon-date">
                {t("active subscription")} {finalDate}
              </p>
            )}
          </div>
        ) : (
          <div className="home__expiry-date">
            <Link to="/subscribe" className="home__expiry-date-link">
              {t("subscription notice")}
            </Link>
          </div>
        )}
      </div>
      <div className="home__header">
        <div className="locationAndDateContainer">
          {city && (
            <div className="locationContainer">
              <span>{suburb}</span>
              <span>{city} </span>
            </div>
          )}
        </div>
        <DataFilter />
      </div>
      <div className="home__postsContainer text-secondary">
        {isSearchLoading && (
          <Suspense
            fallback={<img src={loader} alt="" className="loading__image" />}
          />
        )}

        <div className="posts__container-items">
          {filtered?.map((post) => (
            <PostCard item={post} id={post?.id} key={post?.id} />
          ))}
        </div>
      </div>
    </main>
  );
};

export default SearchedScreen;
