import React, { useState,useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { loader } from "../../Assets";
import { setCustomResponseMessage } from "../../redux/actions";
import { useDispatch } from "react-redux";
import CustomInputField from "../CustomFormFields/CustomInputField";
import CustomTextArea from "../CustomFormFields/CustomTextArea";

const Contact = () => {
  const { t } = useTranslation();
  const emailRef = useRef();
   const nameRef = useRef();
   const messageRef = useRef()
   const dispatch = useDispatch()
   const numberRef = useRef()
 const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });



  const handleInputChange = (e) => {
    const value = e.target.value;

    setFormData({ ...formData, [e.target.name]: value });
  };

 


const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
    try {
      setLoading(true);
        await emailjs.send(serviceId, templateId, {
            fullName: nameRef.current.value,
            email: emailRef.current.value,
            phoneNumber:numberRef.current.value,
            message:messageRef.current.value
           }).then(() => {
            dispatch(setCustomResponseMessage("email successfully sent"))
            setFormData({
              fullName: "",
              email: "",
              phoneNumber: "",
              message: "",
            });
           })
          
           setLoading(false);
    } catch (error) {
      console.log(error);
     dispatch( setCustomResponseMessage(error.TypeError))
      setLoading(false);
    }
  };

  
  return (
    <div className="contact">
      <div className="contact__header">
        <h3>Email us</h3>
      </div>
      <div className="contact__form">
        <form onSubmit={handleSubmit} className="contact__form">
        <CustomInputField
            inputType="text"
            inputName="fullName"
            inputRef={nameRef}
            value={formData.fullName}
            handleInputChange={handleInputChange}
            placeholderText="Full Name"
            />
          <CustomInputField
            inputType="email"
            inputName="email"
            inputRef={emailRef}
            value={formData.email}
            handleInputChange={handleInputChange}
            placeholderText={t("email")}
            />
          <CustomInputField
            inputType="number"
            inputName="phoneNumber"
            inputRef={numberRef}
            value={formData.phoneNumber}
            handleInputChange={handleInputChange}
            placeholderText={t("phone")}
            />
          <CustomTextArea
          inputName="message"
          inputRef={messageRef}
          value={formData.message}
          handleInputChange={handleInputChange}
          placeholderText={t("write message")}
          />
        
          {
           loading && <img src={loader} alt="loading" className="loading__image" />
          }
          <button type="submit" className="register__submit">
          {t("send message")}
        </button>
        </form>
      </div>
    </div>
  );
};
export default Contact;
