import React from "react";
import { IconButton } from "@mui/material";
import { MdPhotoCamera } from "react-icons/md";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import "./styles.css";
import Buttons from "../Buttons/Buttons";

const ImagesUploader = ({
  previewImages,
  handleImageChange,
  removeImage,
  error,
  nextStep,
  validator,
  category
}) => {
  const { t } = useTranslation();

  return (
    <div className="createPost__container">
      <form className="createPost__form">
        <h4 className="image__uploadContainer-header pt-2 text-danger">
          {t("select photos to upload")}
        </h4>
        <h4 className="image__uploadContainer-header pt-2 text-danger">
          {t(category)}
        </h4>
        <div className="image__uploadContainer createPost__input">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              // accept="image/*"
              accept=".jpg, .jpeg, .png"
              type="file"
              multiple
              onChange={handleImageChange}
            />
            <MdPhotoCamera size={50} color="" />
          </IconButton>
        </div>
        <div className="preview__container">
          {previewImages?.map(
            (preview, index) =>
              preview && (
                <div className="my-2">
                  <div className="preview__imageContainer">
                    <img
                      key={index}
                      className="previewImage"
                      src={preview}
                      alt=""
                    />
                    <GrFormClose
                      color="red"
                      size={20}
                      className="close__prevIcon"
                      onClick={() => removeImage(index)}
                    />
                  </div>
                </div>
              )
          )}
          {/* {error !== "" && (
            <label className="error text-danger">{t(error)}</label>
          )} */}
          <label className="text-danger text-left w-100">
            {validator?.message("images", previewImages, `required|images`, {
              messages: {
                required: t(error),
              },
            })}
          </label>
        </div>
        <div className="submit__container">
          <Buttons next={nextStep} />
        </div>
      </form>
    </div>
  );
};

export default ImagesUploader;
