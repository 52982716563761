import React, { useState, useEffect } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import "./postDetails.css";
import ReactWhatsapp from "react-whatsapp";
import BathtubIcon from "@mui/icons-material/Bathtub";
import CallIcon from "@mui/icons-material/Call";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import NumbersIcon from "@mui/icons-material/Numbers";
import { useTranslation } from "react-i18next";
import { RiWhatsappLine } from "react-icons/ri";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PaidIcon from "@mui/icons-material/Paid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TerrainIcon from "@mui/icons-material/Terrain";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { dateConverter, getCoordinates } from "../../utils/utils";
import { scrollToTop } from "../../utils/navUtils";
import { useSelector, useDispatch } from "react-redux";
import {
  checkIfSavedToFavoritesAction,
  getUsersWhoSavedToFavoritesAction,
  toggleSaveFavoritesAction,
} from "../../redux/actions";
import { ToastContainer, toast } from "react-toastify";
import { HelmetMetaData, ImageSlider } from "../../components";

const PostDetails = () => {
  const { user } = useSelector((state) => state?.user);
  const { role, displayName } = user || {};
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUserId = user?.id;
  const { state } = useLocation();
  const { refreshedUser,currency,favoritesButtonTitle, saveNotification } = useSelector(
    (state) => state.posts
  );
  const { isSubscribed } = refreshedUser || {};
  const [number, setNumber] = useState("237677983166");
  const [mapLongitude, setMapLongitude] = useState(-0.09);
  const [mapLatitude, setMapLatitude] = useState(51.505);
  const [position, setPosition] = useState([+mapLatitude, +mapLongitude]);
  const params = useParams();
  const {
    //country,
    city,
    neighborhood,
    price,
    type,
    category,
    description,
    numberOfToilets,
    numberAvailable,
    countryCurrency,
    bedrooms,
    dining,
    parking,
    dimension,
    phoneNumber,
    //postedBy,
    imageUrls,
    timestamp,
    fenced,
    oneBalcony,
    twoBalconies,
    securityGuard,
    surveillanceCamera,
    wardrobe,
    electricity,
    water,
    normalCallNumber,
    airConditioner,
    camWater,
    generator,
    waterHeater,
    interphone,
    cable,
    wifi,
    washingMachine,
    gym,
    swimmingPool,
    partySpace,
    // videoUrl
  } = state?.post || {};
  const finalDate = dateConverter(timestamp);
  const postId = params.id;

  useEffect(() => {
    scrollToTop()
    if (currentUserId) {
      dispatch(checkIfSavedToFavoritesAction({ currentUserId, postId }));
    }
  }, [currentUserId, postId, dispatch]);

  useEffect(() => {
    const getData = async () => {
      const data = await getCoordinates(neighborhood, city);
      console.log(position, data);
      setMapLatitude(parseFloat(data?.lat));
      setMapLongitude(parseFloat(data?.lon));
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPosition((prevState) => [+mapLatitude, +mapLongitude]);
  }, [mapLatitude, mapLongitude]);

  useEffect(() => {
    if (phoneNumber !== null) {
      setNumber(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(getUsersWhoSavedToFavoritesAction(params.id, currentUserId));
    }

    // eslint-disable-next-line
  }, [dispatch, currentUserId, params.id]);

  const toggleSave = async () => {
    if (!displayName) return;
    dispatch(toggleSaveFavoritesAction(state?.post, params.id, currentUserId));
    toast.info(t(saveNotification), {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <div className="post__details">
      <HelmetMetaData
        image="https://firebasestorage.googleapis.com/v0/b/housing-88dc5.appspot.com/o/postImages%2FFB_IMG_17380971879722448.jpg?alt=media&token=f0bcfacc-baa3-4e0f-b27e-027ad96d0679"
        currentUrl="https://www.homescraper.com/post/OfctQNOyLta0wPN7fPNl"
        description="find guest houses for breakfast and bed (B&B), houses to rent,houses and plots of land for sell, solve all your real estate problems.We have brought thousands of homes,plots of land,guest houses and real estate agents closer to you."
        quote="Bringing Home seekers,Homes and  Real Estate agents in one place 🔥🔥🔥🔥"
      ></HelmetMetaData>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
<div className="post__main-info">
<div className="carousel-container">
    <ImageSlider images={imageUrls} indicators={true} arrows={true}/>
      </div>
      <div
        className={
          category === "For Rent" ? "post__fullInfo" : "post__fullInfo-sale"
        }
      >
        <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
          <LocationOnIcon size="medium" color="primary" />
          <h5 className="postDetails__emphasis w-100">
            {" "}
            {neighborhood}
          </h5>
        </div>
        {dimension && (
          <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
            <TerrainIcon size="medium" color="primary" />
            <h5 className="postDetails__emphasis">
              {dimension}/M<sup>2</sup>
            </h5>
          </div>
        )}
        <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
          <MapsHomeWorkIcon size="medium" color="primary" />
          <h5 className="postDetails__emphasis w-100"> {t(type)}</h5>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-1 mb-1">
          <PaidIcon size="medium" color="primary" />
          <h5 className="postDetails__emphasis">
            {price} {!countryCurrency ? currency : countryCurrency}
            {category === "For Rent" && (
              <span>
                {t(type) !== t("guest house")
                  ? t("per month")
                  : t(type) === t("guest house")
                  ? t("per night")
                  : null}
              </span>
            )}
          </h5>
        </div>

        {(type === "simple apartment" || type === "flat") && (
          <div className="d-flex gap-3">
            <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
              <LocalHotelIcon size="medium" color="primary" />
              <h5 className="postDetails__emphasis w-100"> {bedrooms}</h5>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
              <BathtubIcon size="medium" color="primary" />
              <h5 className="postDetails__emphasis w-100">
                {" "}
                {numberOfToilets}
              </h5>
            </div>
            {(category === t("For Rent") && numberAvailable > 1 ) && (
              <>
                <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
                  <NumbersIcon size="medium" color="primary" />
                  <h5 className="postDetails__emphasis w-100">
                    {" "}
                    {t(numberAvailable)}
                  </h5>
                </div>
              </>
            )}
          </div>
        )}

        {camWater && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(camWater)}
          </h5>
        )}
        {interphone && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(interphone)}
          </h5>
        )}
        {generator && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(generator)}
          </h5>
        )}
        {gym && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(gym)}
          </h5>
        )}
        {cable && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(cable)}
          </h5>
        )}
        {washingMachine && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(washingMachine)}
          </h5>
        )}
        {wifi && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(wifi)}
          </h5>
        )}
        {partySpace && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(partySpace)}
          </h5>
        )}
        {swimmingPool && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(swimmingPool)}
          </h5>
        )}
        {waterHeater && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(waterHeater)}
          </h5>
        )}
        {dining && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(dining)}
          </h5>
        )}
        {airConditioner && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(airConditioner)}
          </h5>
        )}
        {water && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(water)}
          </h5>
        )}
        {electricity && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(electricity)}
          </h5>
        )}
        {wardrobe && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(wardrobe)}
          </h5>
        )}
        {surveillanceCamera && (
          <h5 className="postDetails__facility">✅ {t(surveillanceCamera)}</h5>
        )}
        {securityGuard && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(securityGuard)}
          </h5>
        )}
        {twoBalconies && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(twoBalconies)}
          </h5>
        )}
        {oneBalcony && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(oneBalcony)}
          </h5>
        )}
        {fenced && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(fenced)}
          </h5>
        )}
        {parking && (
          <h5 className="postDetails__facility">
            ✅{"  "}
            {t(parking)}
          </h5>
        )}

        {isSubscribed === true ? (
          <>
            <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
              <CallIcon size="medium" color="primary" />
              <h5 className="postDetails__emphasis">
                {normalCallNumber ? normalCallNumber : phoneNumber}
              </h5>
            </div>

            <h5 className="postDetails__facility">
              <ReactWhatsapp
                number={number}
                message={t(
                  "whatsapp message"
                )}
              >
                <div className="whatsappPost__button">
                  <RiWhatsappLine color="#06e651" size={15} />{" "}
                  <h5 className="postDetails__emphasis">
                    {" "}
                    click to send WhatsApp message
                  </h5>
                  {/* <h5 className="subtitle"> click to message {postedBy}</h5> */}
                </div>
              </ReactWhatsapp>{" "}
            </h5>
          </>
        ) : (
          <Link to="/subscribe" className="postDetails__facility2">
            {t("see contact")}
          </Link>
        )}

        <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
          <ScheduleIcon size="medium" color="disabled" />
          <h5 className="postDetails__facility">{finalDate} </h5>
        </div>
        {/* <h5 className="postDetails__facility">
             {t("posted by")} {" "} {postedBy}{" "}
          </h5> */}
        {role && role === "client" && (
          <div className="post__save">
            <button
              className={
                favoritesButtonTitle?.match("saved property")
                  ? "saved__button"
                  : "save__button"
              }
              onClick={toggleSave}
            >
              {t(favoritesButtonTitle)}
            </button>
          </div>
        )}
      </div>
      {description && (
        <div className="post__details-description">
          <p>{description}</p>
        </div>
      )}
      
</div>

      {/* <div className="post__details-video">
      {
        videoUrl && (
          <VideoPlayer videoSrc={videoUrl}/>
        )
       } 
      </div>  */}
      {/* {
            isSubscribed === true ? (
      <div className="post__map">
        <Map item={neighborhood} position={position} lon={mapLongitude} lat={mapLatitude} destination={[mapLongitude,mapLatitude]}/>
      </div>
        ) : (
        <Link to="/subscribe" className="postDetails__facility2">
         {t("see map")}
        </Link>
      )
    }  */}
    </div>
  );
};

export default PostDetails;
