import React from "react";
import { Helmet } from "react-helmet";

export default function HelmetMetaData(props) {
  // let location = useLocation();
  //   console.log(props)
  let currentUrl = props.currentUrl;
  let quote =
    props.quote !== undefined
      ? props.quote
      : "Homescraper - All Rental Property in one place";
  let title = props.title !== undefined ? props.title : "homescraper";
  let image = props.image !== undefined ? props.image : "";
  let description =
    props.description !== undefined
      ? props.description
      : "We discover the latest rental properties and bring the agents closer to you with just one Click.So remember us whenever you need a new house 🔥🔥🔥🔥";
  let hashtag = props.hashtag !== undefined ? props.hashtag : "#homescraper";
  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="description"
        content="Homescraper - All Rental Property in one place"
        data-react-helmet="true"
      />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Homescraper" />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}
