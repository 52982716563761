import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./favorite.css";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import ReactWhatsapp from "react-whatsapp";
import { loader } from "../../../../Assets";
import { useTranslation } from 'react-i18next';
import { RiWhatsappLine } from "react-icons/ri";
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from "react-redux";

const Favorite = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const { currency } = useSelector((state) => state.posts)
    const [number, setNumber] = useState("237677983166");
    const {
      type,
      city,
      neighborhood,
      price,
      category,
      countryCurrency,
      numberOfToilets,
      numberAvailable,
      bedrooms,
      dining,
      parking,
      phoneNumber,
      postedBy,
      imageUrls,
      timestamp,
      fenced,
      oneBalcony,
      twoBalconies,
      securityGuard,
      surveillanceCamera,
      wardrobe,
      electricity,
      water,
      normalCallNumber,
      airConditioner,
      camWater,
      generator,
      waterHeater,
      interphone,
      cable,
    wifi,
    washingMachine,
    gym,
    swimmingPool,
    partySpace,
  
    } = state;
    
    const fieldVal = timestamp;
    const timeStamp = new Timestamp(fieldVal?.seconds, fieldVal?.nanoseconds);
    const date = timeStamp.toDate();
    const finalDate = moment(date).startOf("second").fromNow();
  
    useEffect(() => {
      if (phoneNumber !== null) {
        setNumber(phoneNumber);
      }
    }, [phoneNumber]);
  return (
    <div className="favorite__details">
      <div className="details__heading">
        <h2>{type.toUpperCase()} {t("details")}</h2>
      </div>
      <div className="favoriteDetails__container d-flex justify-content-center align-items-center gap-2 flex-wrap">

        <div className="favorite__fullInfo">
        <h5 className="favoriteDetails__subtitle">
            {city.toUpperCase()}, {neighborhood.toUpperCase()}
          </h5>
          <h5 className="favoriteDetails__emphasis">{price}{" "}
            {!countryCurrency ? currency : countryCurrency}
          </h5>
          {( type === "simple apartment" || type === "flat") && (
            <>
              <h5 className="favoriteDetails__emphasis">
                {bedrooms}{" "}{t("no of bedrooms").toUpperCase()}
              </h5>
              <h5 className="favoriteDetails__emphasis">
                {numberOfToilets}{" "}{t("no of toilets").toUpperCase()}
              </h5>
            </>
          )}
          {
            camWater && (
              <h5 className="postDetails__subtitle">
            {t(camWater)}  ✅
          </h5>
            )
          }
          {
            interphone && (
              <h5 className="postDetails__subtitle">
            {t(interphone)}  ✅
          </h5>
            )
          }
          {
            generator && (
              <h5 className="postDetails__subtitle">
            {t(generator)}  ✅
          </h5>
            )
          }
          {
            waterHeater && (
              <h5 className="postDetails__subtitle">
            {t(waterHeater)}  ✅
          </h5>
            )
          }
          {
            dining && (
              <h5 className="postDetails__subtitle">
            {t(dining)}  ✅
          </h5>
            )
          }
          {
            airConditioner && (
              <h5 className="postDetails__subtitle">
            {t(airConditioner)}  ✅
          </h5>
            )
          }
          {
            water && (
              <h5 className="postDetails__subtitle">
            {t(water)}  ✅
          </h5>
            )
          }
          {
            electricity && (
              <h5 className="postDetails__subtitle">
            {t(electricity)}  ✅
          </h5>
            )
          }
          {
            wardrobe && (
              <h5 className="postDetails__subtitle">
            {t(wardrobe)}  ✅
          </h5>
            )
          }
          {
            surveillanceCamera && (
              <h5 className="postDetails__subtitle">
            {t(surveillanceCamera)}  ✅
          </h5>
            )
          }
          {
            securityGuard && (
              <h5 className="postDetails__subtitle">
            {t(securityGuard)}  ✅
          </h5>
            )
          }
          {
            twoBalconies && (
              <h5 className="postDetails__subtitle">
            {t(twoBalconies)}  ✅
          </h5>
            )
          }
          {
            oneBalcony && (
              <h5 className="postDetails__subtitle">
            {t(oneBalcony)}  ✅
          </h5>
            )
          }
          {
            fenced && (
              <h5 className="postDetails__subtitle">
            {t(fenced)}  ✅
          </h5>
            )
          }
          {
            parking && (
              <h5 className="postDetails__subtitle">
            {t(parking)}  ✅
          </h5>
            )
          }
          {
            gym && (
              <h5 className="postDetails__subtitle">
            {t(gym)}  ✅
          </h5>
            )
          }
          {
            cable && (
              <h5 className="postDetails__subtitle">
            {t(cable)}  ✅
          </h5>
            )
          }
          {
            washingMachine && (
              <h5 className="postDetails__subtitle">
            {t(washingMachine)}  ✅
          </h5>
            )
          }
          {
            wifi && (
              <h5 className="postDetails__subtitle">
            {t(wifi)}  ✅
          </h5>
            )
          }
          {
            partySpace && (
              <h5 className="postDetails__subtitle">
            {t(partySpace)}  ✅
          </h5>
            )
          }
          {
            swimmingPool && (
              <h5 className="postDetails__subtitle">
            {t(swimmingPool)}  ✅
          </h5>
            )
          }
          {
            category === "For Rent" && (
              <>
                {/* <h5 className="favoriteDetails__subtitle">
             {quality}
          </h5> */}
                <h5 className="favoriteDetails__subtitle">
                  {numberAvailable} {" "} {t("no available")}
                </h5>
              </>
            )
          }
          <h5 className="favoriteDetails__emphasis">
          {t("callsTwo")}{" "}{normalCallNumber ? normalCallNumber : phoneNumber}
          </h5>
          <h5 className="favoriteDetails__subtitle">
            <ReactWhatsapp
              number={number}
              message={`Hello sir/madame, good day!! you've reached ${postedBy}`}
            >
             <div className="whatsappFavorite__button">
                <RiWhatsappLine
                color="#06e651"
                size={15}
              />{" "}<h5 className="favoriteDetails__emphasis"> click to message {postedBy}</h5>
                </div>
            </ReactWhatsapp>{" "}
          </h5>
          {/* <h5 className="favoriteDetails__subtitle">
            {t("posted by")?.toUpperCase()} {" "} {postedBy?.toUpperCase()}{" "}
          </h5> */}
          <h5 className="favoriteDetails__subtitle">
            {finalDate}{" "}
          </h5>
        </div>
        <div className="carousel-container">
          {imageUrls?.length === 0 ? (
            <img src={loader} alt="" className="loading__image" />
          ) : (
            <Carousel
              className='carousel'
              axis='horizontal'
              dynamicHeight={true}
              showArrows={true}
              showIndicators={false}
              showThumbs={false}
              autoPlay={false}
              infiniteLoop={false}
              showStatus={false}
            >
              {
                imageUrls.map((image) => (
                  <div className="imageSlider__images">
                    <img src={image} alt='details slider' loading="lazy" className='' key={image.id} />
                  </div>
                ))
              }
            </Carousel>
          )}
        </div>
      </div>
    </div>
  )
}

export default Favorite