import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import "./footer.css";
import { logo } from "../../Assets";
import Contact from "../contact/Contact";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer__pages">
        <div className="footer__links">
          {/* <Link to='/' className="footer_link"> {t("home")}</Link> */}
          {/* <Link to='/contact' className="footer_link">Contact</Link>
       <Link to='support' className="footer_link">Support</Link> */}
          <Link to="/">
            <div className="footer__logo-container">
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <div className="footer__social-links">
            <Link
              to="https://web.facebook.com/profile.php?id=61572417011864"
              target="_blank"
            >
              <FacebookIcon fontSize="large" color="primary" />
            </Link>
            <Link to="">
              <XIcon fontSize="large" color="primary" />
            </Link>
          </div>
        </div>
        <div className="footer__container">
          <div className="footer__contacts">
            <span className="footer__contactsTitle"> {t("contact")}</span>
            <div className="footer__contact">
              {/* <BsWhatsapp size={20} color='#06e651'/> */}
              <ReactWhatsapp
                style={{ backgroundColor: "inherit" }}
                number={"+237677972126"}
                message={`Hello sir/madame, good day!! I am reaching out from www.homescraper.com`}
              >
                <span className="whatsappFooter__button">{t("message")}</span>
              </ReactWhatsapp>
              <Contact />
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <span className="website__nameFooter">HOMESCRAPER &copy; 2023</span>
      </div>
    </footer>
  );
};

export default Footer;

// Hello friends 😊 !! am excited to share with you the progress of this project of mine. The idea to build a real estate platform was inspired by the struggle I had finding a house for myself and the suffering people go through.With 315 listed properties on the platform, I can heartily say I made it!! 😊 . Visit https://homescraper.com to have a look and feel.Thanks for sharing
