import React from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

const Buttons = ({ previous, next }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between align-items-center">
      {previous && (
        <button type="button" className="next" onClick={previous}>
          {t("previous")}
        </button>
      )}
      {next && (
        <button type="button" className="previous" onClick={next}>
          {t("next")}
        </button>
      )}
    </div>
  );
};

export default Buttons;
