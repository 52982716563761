import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { finalDateConverter, today } from "../../utils/utils";
import DataFilter from "../Filter/DataFilter";
import { clearFilterInput, switchTabAction } from "../../redux/actions";
import Search from "../Search/Search";

const MainHeader = ({ filteredApartments, data,coords }) => {
  const {
    subscriptionExpiryDate,
    filterValue,
    totalRentalProperties,
    currentTab,
    forSale,
    forRent,
    geolocationData,
  } = useSelector((state) => state.posts);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const city = user?.city;
  const currentTown = city || geolocationData?.data?.city;
  const finalDate = finalDateConverter(subscriptionExpiryDate);
  const currentUserId = user?.id;

  const handleSwitchTab = useCallback(
    (currentSection) => {
      if (filterValue !== "") {
        dispatch(clearFilterInput());
      }
      dispatch(switchTabAction(currentSection));
    },
    [dispatch, filterValue]
  );
  return (
    <main className="main__header">
      {(window.location.pathname === "/" ||
        window.location.pathname === "/for-sale") && (
        <>
          <div className="search__container">
            <Search />
          </div>
          <div className="home__sub-link">
            <div className="home__sub">
              <button
                className={
                  currentTab === "houses"
                    ? "active__header-tab"
                    : "dormant__header-tab"
                }
                onClick={() => handleSwitchTab("houses")}
              >
                {t("properties button")}
              </button>
              <button
                className={
                  currentTab === "requests"
                    ? "active__header-tab"
                    : "dormant__header-tab"
                }
                onClick={() => handleSwitchTab("requests")}
              >
                {t("request button")}
              </button>
            </div>
          </div>
          <main className="subscription_notice">
            <h4 className="totalRentalProperties">
              {totalRentalProperties} {t("available properties")}
            </h4>
            {
              coords?.latitude && <div className="w-100 d-flex gap-3 justify-content-center totalRentalProperties">
              <span>Lat:{coords?.latitude}</span>
              <span>Long:{coords?.longitude}</span>
             </div>
            }
            {window.location.pathname === "/" ? (
              <h4 className="totalRentalProperties">
                {forRent?.length} {t("near you")} {currentTown}
              </h4>
            ) : (
              <h4 className="totalRentalProperties">
                {forSale?.length} {t("near you")} {currentTown}
              </h4>
            )}
            {/* <h4 className="totalRentalProperties">
          Latitude:{locationInfo.latitude} : Longitude:{locationInfo.longitude}
        </h4> */}
            {subscriptionExpiryDate && currentUserId ? (
              <div className="home__expiry-date">
                {today > subscriptionExpiryDate ? (
                  <Link to="/subscribe" className="home__expired-date">
                    {t("expired subscription")} {finalDate}
                  </Link>
                ) : (
                  <p className="home__expire-soon-date">
                    {t("active subscription")} {finalDate}
                  </p>
                )}
              </div>
            ) : (
              <div className="home__expiry-date">
                <Link to="/register" className="home__expiry-date-link">
                  {t("subscription notice")}
                </Link>
              </div>
            )}
            <DataFilter posts={filteredApartments} data={data} />
          </main>
        </>
      )}
    </main>
  );
};

export default MainHeader;
