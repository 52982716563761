import React from "react";
import "./createPost.css";
import { useTranslation } from "react-i18next";
import { Buttons } from "../../../../../components";
const FacilitiesSection = ({ inputChange, prevStep, nextStep }) => {
  const { t } = useTranslation();

  return (
    <div className="createPost__container">
      <form onSubmit={nextStep} className="createPost__form">
        <div className="createPost__input">
          <div className="d-flex flex-wrap w-100 justify-content-center align-items-center gap-2">
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("gym")}
              <input
                autocomplete="on"
                type="checkbox"
                name="gym"
                value="gym"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("wifi")}
              <input
                autocomplete="on"
                type="checkbox"
                name="wifi"
                value="wifi"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("cable")}
              <input
                autocomplete="on"
                type="checkbox"
                name="cable"
                value="cable"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center" >
              {t("swimmingPool")}
              <input
                autocomplete="on"
                type="checkbox"
                name="swimmingPool"
                value="swimmingPool"
                onChange={inputChange}
                className=""
              />
            </label>
            <div className="text-white labelWidth d-flex justify-content-start align-items-center">
              <label className="checkbox-label" htmlFor="">{t("washingMachine")}</label>
              <input
                autocomplete="on"
                type="checkbox"
                name="washingMachine"
                value="washingMachine"
                onChange={inputChange}
                className="checkbox-input"
              />
            </div>
            
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("partySpace")}
              <input
                autocomplete="on"
                type="checkbox"
                name="partySpace"
                value="partySpace"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
          </div>
        </div>
        <div className="submit__container">
        <Buttons next={nextStep} previous={prevStep} />
        </div>
      </form>
    </div>
  );
};

export default FacilitiesSection;
