import React from "react";

const CustomTextArea = ({
  value,
  handleInputChange,
  placeholderText,
  inputName,
  inputRef,
}) => (
  <div className="form__field m-0">
    <textarea
      rows={5}
      cols={10}
      name={inputName}
      value={value}
      placeholder={placeholderText}
      onChange={handleInputChange}
      className=""
      ref={inputRef}
      required
    />
  </div>
);

export default CustomTextArea;
