import React, { useState, useEffect } from "react";
import { db } from "../../../../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useValidator from "../../../../utils/CustomValidator";
import PhoneInput from "react-phone-input-2";
import { loader } from "../../../../Assets";
import { setCustomResponseMessage } from "../../../../redux/actions";
import {
  CustomInputField,
  CustomSelectCities,
  CustomSelectField,
} from "../../../../components";
import {
  apartmentTypes,
  phoneNumberInputStyles,
} from "../../../../utils/utils";
import './request.css'

const MyRequestScreen = () => {
  const { user, locationData } = useSelector((state) => state?.user);
  const { id, displayName } = user || {};
  const [validator, showValidationMessage] = useValidator();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [countryCode, country, citiesData] = locationData;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    type: "",
    budget: "",
    city: "",
    neighborhood: "",
    bedrooms: "",
    numberOfToilets: "",
    longDescription: "",
  });

  useEffect(() => {
    const result = document
      ?.getElementById("phone__input")
      ?.getElementsByClassName("special-label");
    result[0].style.display = "none";
  }, []);

  const handlePhoneInputChange = (phone) => {
    setPhoneNumber(phone);
  };

  const inputChange = (e) => {
    console.log(countryCode, country);
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleAddProperty = (e) => {
    e.preventDefault();
    try {
      if (validator.allValid()) {
        setLoading(true);
        // dispatch(createPostAction(postData,id,phoneNumber,urls,navigate))
        addDoc(collection(db, "clientRequests"), {
          timestamp: serverTimestamp(),
          postedBy: displayName,
          userId: id,
          type: formData.type,
          city: formData.city,
          phoneNumber: phoneNumber,
          neighborhood: formData.neighborhood,
          bedrooms: formData.bedrooms,
          numberOfToilets: formData.numberOfToilets,
          budget: formData.budget,
          longDescription: formData.longDescription,
        });
        dispatch(setCustomResponseMessage("create request successful"));
        setFormData({
          type: "",
          budget: "",
          city: "",
          neighborhood: "",
          bedrooms: "",
          numberOfToilets: "",
          longDescription: "",
        });
        setLoading(false);
        // navigate("/");
      } else {
        showValidationMessage(true);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="w-100 createPost__container">
      <div className="">
        <form onSubmit={handleAddProperty} className="createPost__form">
          <CustomSelectField
            labelId="type-select"
            selectId="typeSelect"
            labelName={t("select type")}
            inputName="type"
            value={formData.type}
            handleInputChange={inputChange}
            data={apartmentTypes}
            validator={validator}
          />
          <CustomInputField
            inputType="text"
            placeholderText={t("budget")}
            inputName="budget"
            value={formData.budget}
            handleInputChange={inputChange}
            className=""
          />

          {(formData?.type === "simple apartment" ||
            formData?.type === "flat" ||
            formData?.type === "guest house") && (
            <>
              <CustomInputField
                inputType="number"
                inputName="bedrooms"
                placeholderText={t("bedrooms")}
                value={formData.bedrooms}
                handleInputChange={inputChange}
                validator={validator}
              />
              <CustomInputField
                inputType="number"
                inputName="numberOfToilets"
                placeholderText={t("bathrooms")}
                value={formData.numberOfToilets}
                handleInputChange={inputChange}
                validator={validator}
              />
            </>
          )}
          <CustomSelectCities
            labelId="city-select"
            selectId="citySelect"
            labelName="city"
            inputName="city"
            handleInputChange={inputChange}
            value={formData.city}
            data={citiesData}
            optionValue="for rent"
          />
          <CustomInputField
            inputType="text"
            placeholderText={t("quarter")}
            inputName="neighborhood"
            value={formData.neighborhood}
            handleInputChange={inputChange}
            validator={validator}
          />

          <div className="form__field">
            <textarea
              autocomplete="on"
              rows={5}
              cols={10}
              maxLength={200}
              placeholder={t("longDescription")}
              name="longDescription"
              value={formData.longDescription}
              onChange={inputChange}
              className="form__field-input"
            />
            <label className="register__error text-danger">
              {validator.message(
                "longDescription",
                formData.longDescription,
                "required|longDescription",
                {
                  messages: {
                    required: t("required"),
                  },
                }
              )}
            </label>
          </div>
          <div className="createPost__input" id="phone__input">
            <PhoneInput
              placeholder="WhatsApp"
              type="text"
              country={"cm"}
              enableAreaCodes={true}
              onlyCountries={["cm"]}
              // areaCodes={{us: ['999']}}
              inputProps={{
                name: "phoneNumber",
                country: "cm",
                required: true,
                autoFocus: true,
              }}
              // containerStyle={{
              //   Padding: "5px",
              // }}
              inputStyle={phoneNumberInputStyles}
              regions={["north-america", "africa", "europe"]}
              value={phoneNumber}
              onChange={handlePhoneInputChange}
              // onChange={props.inputChange}
              // value={props.formData.phonNumber}
              isValid={(value, country) => {
                if (value.match(/12345/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
            />
            <label className="error text-danger">
              {validator.message(
                "phoneNumber",
                phoneNumber,
                "required|phoneNumber",
                {
                  messages: {
                    required: t("required"),
                  },
                }
              )}
            </label>
          </div>
          {loading && <img src={loader} alt="" className="loading__image" />}
          <div className="submit__container">
            <button className="next">{t("submit request")}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyRequestScreen;
