import React, { useState, useEffect } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { auth } from "../../../firebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../../Assets/index";
import {
  clearMessageAction,
  //googleAuthSignInAction,
  signInAction,
} from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import { CustomInputField } from "../../../components";
// import {
//   GoogleLoginButton,
// } from 'react-social-login-buttons';
// //
const Login = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, isLoading } = useSelector((state) => state.user);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  useEffect(() => {
    dispatch(clearMessageAction());
  }, [dispatch]);

  const handlePasswordReset = (e) => {
    e.preventDefault();
    if (formData.email) {
      sendPasswordResetEmail(auth, formData.email).then(() => {
        auth.useDeviceLanguage();
        toast.info(t("reset password"), {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signInAction(formData, navigate));
  };

  //   const onLoginStart = () => {
  // dispatch(googleAuthSignInAction(navigate))
  //   };

  return (
    <div className="login1">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <form className="login__form1" onSubmit={handleSubmit}>
        <h3 className="login__header">{t("login message")}</h3>
        <CustomInputField
          inputType="email"
          inputName="email"
          handleInputChange={handleInputChange}
          value={formData.email}
          placeholderText={t("email")}
        />

        <div className="login__input">
          <CustomInputField
            inputType={showPassword ? "text" : "password"}
            handleInputChange={handleInputChange}
            value={formData.password}
            placeholderText={t("password")}
            inputName="password"
          />

          {showPassword ? (
            <AiFillEyeInvisible
              className="login__show-password"
              onClick={handleShowPassword}
            />
          ) : (
            <AiFillEye
              className="login__show-password"
              onClick={handleShowPassword}
            />
          )}
        </div>
        <div className="login__support">
          <button
            className="login__supportLink"
            type="button"
            onClick={handlePasswordReset}
          >
            {t("forgot password")}{" "}
          </button>
          <Link to="/register" className="login__supportLink">
            {t("no account")}
          </Link>
        </div>
        <button className="login__submit">{t("login")}</button>
        {/* <div style={{width:"auto"}}>
          <GoogleLoginButton
         align="left"
         className="googleButton"
         size="35px"
         iconSize="20px"
         onClick={onLoginStart}/></div> */}
        {isLoading && <img src={loader} alt="" className="loading__image" />}
        {message && (
          <div className="form-group">
            <div className="alert alert-danger login__error" role="alert">
              {message}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Login;
