import React, { useState} from 'react'
import Paper from "@mui/material/Paper";
import AddPropertyForRent from './AddPropertyForRent';
import AddPropertyForSell from '../ForSell/AddPropertyForSell';
import { useTranslation } from 'react-i18next';

const CreatePostMainContainer = () => {
    const { t } = useTranslation();
    const [category, setCategory] = useState("for rent");
  return (
    <Paper
    elevation={3}
   className="w-100 d-flex flex-column justify-content-center align-items-center"
  >
    <div className="d-flex justify-content-start align-items-center promotionScreen_header">
      <button className={category === "for rent"? "promotionActive" : "promotion"} onClick={() => setCategory("for rent")}>{t("for rent")}</button>
      <button className={category === "for sale"? "promotionActive" : "promotion"} onClick={() => setCategory("for sale")}>{t("for sale")}</button>
      </div>
      {
      category === "for rent" ? (<AddPropertyForRent category={category} />)
      : ( <AddPropertyForSell category={category}/>)
}
</Paper>
  )
}

export default CreatePostMainContainer