import {
  CreatePost,
  Dashboard,
  // EditForSale,
  EditPost,
  //ForSaleScreen,
  SearchedScreen,
  NoPropertyFallback,
  Login,
  PostDetails,
  Profile,
  Register,
  VerifyEmail,
  Favorites,
  Favorite,
  Subscription,
  MyRequestScreen,
  AllRentalProperties,
  AllRequestsScreen,
  RentalsLandingScreen,
  NoInternetFallback,
} from "../pages";

export const navLinks = [
  // { link: "/for-sale", element: ForSaleScreen },
  { link: "/network-error", element: NoInternetFallback },
  { link: "/landing", element: RentalsLandingScreen },
  { link: "/post/:id", element: PostDetails },
  { link: "/register", element: Register },
  { link: "/login", element: Login },
  { link: "/subscribe", element: Subscription },
  { link: "/verify-email", element: VerifyEmail },
  { link: "/profile/:name", element: Profile },
  { link: "/:id/favorites", element: Favorites },
  { link: "/:id/my-request", element: MyRequestScreen },
  { link: "/favorite/:id", element: Favorite },
  { link: "/:id/create-post", element: CreatePost },
  { link: "/:id/edit-post/:id", element: EditPost },
  { link: "/:id/dashboard", element: Dashboard },
  { link: "/no-data-fallback", element: NoPropertyFallback },
  { link: "/searched-properties", element: SearchedScreen },
  { link: "/all-rental-properties", element: AllRentalProperties },
  { link: "/all-requests", element: AllRequestsScreen },
];

export function scrollToTop() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}