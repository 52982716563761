import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { loader } from "../../../Assets";
//import { postActions } from '../../../store/postSlice/postSlice';
//import './css'
// import {
//   Box,
//   IconButton,
//   LinearProgress,
// } from "@mui/material";
import useValidator from "../../../utils/CustomValidator";
import { auth, db } from "../../../firebaseConfig";
import { updateDoc, doc, serverTimestamp } from "firebase/firestore";
import PhoneInput from "react-phone-input-2";
import "./createPost.css";
import { useTranslation } from "react-i18next";
import {
  CustomInputField,
  CustomSelectCities,
  CustomSelectField,
} from "../../../components";
import { apartmentTypes, phoneNumberInputStyles } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { scrollToTop } from "../../../utils/navUtils";

const propertyType = ["land", "house"];
const EditPost = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validator, showValidationMessage] = useValidator();
  const { locationData } = useSelector((state) => state?.user);
  const { citiesData} = locationData;

  const [error, setError] = useState("");
  const { id } = useParams();

  const {
    type,
    city,
    neighborhood,
    price,
    numberAvailable,
    numberOfToilets,
    bedrooms,
    dining,
    parking,
    phoneNumber,
    userId,
    streetNumber,
    postalCode,
    fenced,
    oneBalcony,
    twoBalconies,
    securityGuard,
    surveillanceCamera,
    wardrobe,
    electricity,
    water,
    normalCallNumber,
    airConditioner,
    camWater,
    generator,
    waterHeater,
    interphone,
    cable,
    wifi,
    washingMachine,
    gym,
    swimmingPool,
    partySpace,
    category,
    dimension,
    description
  } = state?.post;
  const [updateData, setUpdateData] = useState({
    type: type,
    city: city,
    neighborhood: neighborhood,
    streetNumber: streetNumber,
    postalCode: postalCode,
    price: price,
    numberAvailable: numberAvailable,
    bedrooms: bedrooms,
    numberOfToilets: numberOfToilets,
    dining: dining,
    parking: parking,
    fenced: fenced,
    oneBalcony: oneBalcony,
    twoBalconies: twoBalconies,
    securityGuard: securityGuard,
    surveillanceCamera: surveillanceCamera,
    wardrobe: wardrobe,
    electricity: electricity,
    water: water,
    normalCallNumber: normalCallNumber,
    airConditioner: airConditioner,
    camWater: camWater,
    generator: generator,
    waterHeater: waterHeater,
    interphone: interphone,
    cable: cable,
    wifi: wifi,
    washingMachine: washingMachine,
    gym: gym,
    swimmingPool: swimmingPool,
    partySpace: partySpace,
    dimension: dimension,
    description:description
  });
  const [newPhoneNumber, setNewPhoneNumber] = useState(phoneNumber);

  useEffect(() => {
    scrollToTop()
    const result = document
      .getElementById("phone__input")
      .getElementsByClassName("special-label");
    result[0].style.display = "none";
  }, []);

  const handlePhoneInputChange = (phone) => {
    setNewPhoneNumber(phone);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    //const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(value);
    setUpdateData({ ...updateData, [e.target.name]: value });
  };

  const handleCheckboxInputChange = (e, newValue) => {
    let value = e.target.value;
    console.log(e.target.checked);
    console.log("value 1", value);
    console.log("value 2", newValue);
    if (e.target.value === "") {
      setUpdateData({ ...updateData, [e.target.name]: newValue });
    } else if (e.target.value !== "") {
      let value = "";
      setUpdateData({ ...updateData, [e.target.name]: value });
    }
  };
  console.log(updateData);
  console.log(category);
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const taskDocRef = doc(db, "apartments", id);
    if (auth?.currentUser?.uid === userId && validator.allValid()) {
      try {
        if(category === "For Rent"){
          await updateDoc(taskDocRef, {
            timestamp: serverTimestamp(),
            userId: userId,
            type: updateData.type,
            city: updateData.city,
            phoneNumber: newPhoneNumber,
            neighborhood: updateData.neighborhood,
            price: updateData.price,
            numberAvailable: updateData.numberAvailable,
            bedrooms: updateData.bedrooms,
            numberOfToilets: updateData.numberOfToilets,
            dining: updateData.dining,
            parking: updateData.parking,
            fenced: updateData.fenced,
            oneBalcony: updateData.oneBalcony,
            twoBalconies: updateData.twoBalconies,
            securityGuard: updateData.securityGuard,
            surveillanceCamera: updateData.surveillanceCamera,
            wardrobe: updateData.wardrobe,
            electricity: updateData.electricity,
            water: water,
            normalCallNumber: updateData.normalCallNumber,
            camWater: camWater,
            cable: updateData.cable,
            wifi: updateData.wifi,
            washingMachine: updateData.washingMachine,
            gym: gym,
            swimmingPool: updateData.swimmingPool,
            partySpace: updateData.partySpace,
            airConditioner: updateData.airConditioner,
          });
          setLoading(false);
          navigate("/");
        }else{
          await updateDoc(taskDocRef, {
            timestamp: serverTimestamp(),
            userId: userId,
            type: updateData.type,
            city: updateData.city,
            phoneNumber: newPhoneNumber,
            neighborhood: updateData.neighborhood,
            price: updateData.price,
            dimension: updateData?.dimension,
            description: updateData?.description,
          });
          setLoading(false);
          navigate("/");
        }
       
      } catch (err) {
        setError(err?.message);
      }
    } else {
      showValidationMessage(true);
      setLoading(false);
    }
  };

  return (
    <div className="register__container py-3">
      <form onSubmit={handleUpdate} className="register__form ">
        <div className="post__header">
          <h2>{t("edit post")}</h2>
        </div>
        {
          type === "For Sale" && <CustomInputField
          inputType="number"
          inputName="dimension"
          placeholderText="Dimension"
          value={updateData.dimension}
          handleInputChange={handleInputChange}
          validator={validator}
        />
        }
        <CustomInputField
          inputType="number"
          inputName="price"
          placeholderText={t("price")}
          value={updateData.price}
          handleInputChange={handleInputChange}
          validator={validator}
        />
        <CustomSelectField
          labelId="type-select"
          selectId="typeSelect"
          labelName={t("select type")}
          inputName="type"
          value={updateData.type}
          handleInputChange={handleInputChange}
          data={category === "For Rent" ? apartmentTypes : propertyType}
          validator={validator}
        />

        <CustomSelectCities
          labelId="city-select"
          selectId="citySelect"
          labelName={t("select city")}
          inputName="city"
          handleInputChange={handleInputChange}
          value={updateData?.city}
          validator={validator}
          data={citiesData}
        />

        <CustomInputField
          inputType="text"
          placeholderText={t("quarter")}
          inputName="neighborhood"
          value={updateData.neighborhood}
          handleInputChange={handleInputChange}
          validator={validator}
        />

        {category === "For Rent" && (
          <>
            <CustomInputField
              inputType="number"
              placeholderText={t("calls")}
              inputName="normalCallNumber"
              value={updateData?.normalCallNumber}
              handleInputChange={handleInputChange}
              validator={validator}
            />
            <CustomInputField
              inputType="number"
              inputName="numberAvailable"
              placeholderText={t("no available")}
              value={updateData?.numberAvailable}
              handleInputChange={handleInputChange}
              validator={validator}
            />
            {(updateData?.type === "simple apartment" ||
              updateData?.type === "flat") && (
              <>
                <CustomInputField
                  inputType="number"
                  inputName="bedrooms"
                  placeholderText={t("bedrooms")}
                  value={updateData.bedrooms}
                  handleInputChange={handleInputChange}
                  validator={validator}
                />
                <CustomInputField
                  inputType="number"
                  inputName="numberOfToilets"
                  placeholderText={t("bathrooms")}
                  value={updateData.numberOfToilets}
                  handleInputChange={handleInputChange}
                  validator={validator}
                />
              </>
            )}
          </>
        )}

        
        {category === "For Rent" && (
          <div className="createPost__input">
            <div className="d-flex flex-wrap w-100 justify-content-center align-items-center gap-1">
              <label className="text-white labelWidth d-flex justify-content-start align-items-center">
                {t("bore hole")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="water"
                  value={updateData.water}
                  defaultChecked={updateData?.water ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "bore hole")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("heater")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="waterHeater"
                  value={updateData.waterHeater}
                  defaultChecked={updateData?.waterHeater ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "heater")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("water")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="camWater"
                  value={updateData.camWater}
                  defaultChecked={updateData?.camWater ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "water")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("generator")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="generator"
                  value={updateData.generator}
                  onChange={(e) => handleCheckboxInputChange(e, "generator")}
                  defaultChecked={updateData?.generator ? true : false}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("interphone")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="interphone"
                  value={updateData.interphone}
                  defaultChecked={updateData?.interphone ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "interphone")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("electricity")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="electricity"
                  value={updateData.electricity}
                  defaultChecked={updateData?.electricity ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "electricity")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("wardrobe")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="wardrobe"
                  value={updateData.wardrobe}
                  defaultChecked={updateData?.wardrobe ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "wardrobe")}
                  className="mx-2"
                />
              </label>

              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("fenced")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="fenced"
                  value={updateData.fenced}
                  defaultChecked={updateData?.fenced ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "fenced")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("dining")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="dining"
                  value={updateData.dining}
                  defaultChecked={updateData?.dining ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "dining")}
                  className="mx-2"
                />
              </label>
              <label className="d-flex justify-content-evenly labelWidth align-items-center text-white border">
                {t("parking")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="parking"
                  value={updateData.parking}
                  defaultChecked={updateData?.parking ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "parking")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth  text-center d-flex justify-content-evenly align-items-center">
                {t("a.c")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="airConditioner"
                  value={updateData.airConditioner}
                  defaultChecked={updateData?.airConditioner ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "a.c")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("camera")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="surveillanceCamera"
                  value={updateData.surveillanceCamera}
                  defaultChecked={updateData?.surveillanceCamera ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "camera")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("guard")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="securityGuard"
                  value={updateData.securityGuard}
                  defaultChecked={updateData?.securityGuard ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "guard")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("balconies")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="twoBalconies"
                  value={updateData.twoBalconies}
                  defaultChecked={updateData?.twoBalconies ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "balconies")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("balcony")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="oneBalcony"
                  value={updateData.oneBalcony}
                  defaultChecked={updateData?.oneBalcony ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "balcony")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("gym")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="gym"
                  value={updateData.gym}
                  defaultChecked={updateData?.gym ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "gym")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("wifi")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="wifi"
                  value={updateData.wifi}
                  defaultChecked={updateData?.wifi ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "wifi")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("swimmingPool")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="swimmingPool"
                  value={updateData.swimmingPool}
                  defaultChecked={updateData?.swimmingPool ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "swimmingPool")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("partySpace")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="partySpace"
                  value={updateData.partySpace}
                  defaultChecked={updateData?.partySpace ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "partySpace")}
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("washingMachine")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="washingMachine"
                  value={updateData.washingMachine}
                  defaultChecked={updateData?.washingMachine ? true : false}
                  onChange={(e) =>
                    handleCheckboxInputChange(e, "washingMachine")
                  }
                  className="mx-2"
                />
              </label>
              <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                {t("cable")}
                <input
                  autocomplete="on"
                  type="checkbox"
                  name="cable"
                  value={updateData.cable}
                  defaultChecked={updateData?.cable ? true : false}
                  onChange={(e) => handleCheckboxInputChange(e, "cable")}
                  className="mx-2"
                />
              </label>
            </div>
          </div>
        )}

        <div className="register__input" id="phone__input">
          <PhoneInput
            type="text"
            country={"cmr"}
            enableAreaCodes={true}
            // onlyCountries={['us','de','ro']}
            // areaCodes={{us: ['999']}}

            inputProps={{
              name: "phoneNumber",
              country: "cm",
              required: true,
              autoFocus: true,
            }}
            // containerStyle={{
            //   Padding: "5px",
            // }}
            inputStyle={phoneNumberInputStyles}
            regions={["north-america", "africa", "europe"]}
            value={newPhoneNumber}
            onChange={handlePhoneInputChange}
            // onChange={props.inputChange}
            // value={props.data.phonNumber}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
          <label className="error text-danger">
            {validator.message(
              "phoneNumber",
              phoneNumber,
              "required|phoneNumber",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="imageUpload__error">
          <span className="text-danger">{error}</span>
        </div>
        {loading && <img src={loader} alt="" className="loading__image" />}
        <button type="submit" className="register__submit">
          {t("edit post")}
        </button>
        {error && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default EditPost;
