import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RequestCard } from "../../../components";

const AllRequestsScreen = () => {
  const navigate = useNavigate();
  const { forRentRequests } = useSelector((state) => state.posts);

  if (forRentRequests.length === 0) {
    navigate("/no-data-fallback");
  }
  return (
    <main className="home__screen" id="top">
      <Helmet>
      <title>Real Estate & Homes for Sale in Cameroon,Rentals,Guest House</title>
          <meta
            name="description"
           content="Find guest houses for breakfast and bed (B&B), houses to rent,houses and plots of land for sell,and solve all your real estate problems.We have brought thousands of homes,plots of land,guest houses and real estate agents closer to you.From the comfort of your home and with your phone or laptop,access our thousand of property and speak with real estate agents with one click"
          />
          <meta
            name="keywords"
            content="real estate in Cameroon, homes for sale in Cameroon,rentals,guest house "
          />
      </Helmet>
      <div className="home__clients-requests-container">
        <div className="home__clients-requests">
          {forRentRequests?.map((request) => (
            <RequestCard item={request} />
          ))}
        </div>
      </div>
    </main>
  );
};

export default AllRequestsScreen;
