import React from "react";
import "./createPost.css";
import { useTranslation } from "react-i18next";
import { loader } from "../../../../../Assets";
import { Buttons } from "../../../../../components";
const FacilitiesThree = ({
  inputChange,
  submitForm,
  prevStep,
  error,
  loading,
  message,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <div className="createPost__container">
      <form onSubmit={handleSubmit} className="createPost__form">
        <div className="createPost__input">
          <div className="d-flex flex-wrap w-100 justify-content-center align-items-center gap-2">
            <label className="text-white labelWidth  text-center d-flex justify-content-evenly align-items-center">
              {t("a.c")}
              <input
                autocomplete="on"
                type="checkbox"
                name="airConditioner"
                value="a.c"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("dining")}
              <input
                autocomplete="on"
                type="checkbox"
                name="dining"
                value="dining"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="d-flex justify-content-evenly labelWidth align-items-center text-white border">
              {t("parking")}
              <input
                autocomplete="on"
                type="checkbox"
                name="parking"
                value="parking"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("camera")}
              <input
                autocomplete="on"
                type="checkbox"
                name="surveillanceCamera"
                value="camera"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("guard")}
              <input
                autocomplete="on"
                type="checkbox"
                name="securityGuard"
                value="guard"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("balconies")}
              <input
                autocomplete="on"
                type="checkbox"
                name="twoBalconies"
                value="balconies"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
            <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
              {t("balcony")}
              <input
                autocomplete="on"
                type="checkbox"
                name="oneBalcony"
                value="balcony"
                onChange={inputChange}
                className="mx-2"
              />
            </label>
          </div>
        </div>
        <div className="imageUpload__error">
          <span className="text-danger">{error}</span>
        </div>
        {loading && <img src={loader} alt="" className="loading__image" />}
        <div className="submit__container">
          <button
            type="submit"
            className={
              message === "upload success" ? "next m-0" : "previous m-0"
            }
          >
            {message === "upload success" ? t("finish") : t("create")}
          </button>
          <Buttons previous={prevStep} />
        </div>
        {error && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default FacilitiesThree;
