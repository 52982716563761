import React, { useState } from "react";
import useValidator from "../../../../../utils/CustomValidator";
import { db, storage } from "../../../../../firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import "./createPost.css";
import { useTranslation } from "react-i18next";
import {
  useDispatch,
  useSelector,
  //useDispatch
} from "react-redux";
import { useNavigate } from "react-router-dom";
import SectionOne from "./SectionOne";
import PropertyLocationSection from "./PropertyLocationSection";
import FacilitiesThree from "./FacilitiesThree";
import FacilitiesOne from "./FacilitiesOne";
import FacilitiesTwo from "./FacilitiesTwo";
import {
  setCustomResponseMessage,
  setLocationData,
} from "../../../../../redux/actions";
import ImagesSection from "../ImagesSection";

///import { createPostAction } from "../../../redux copy/actions";
const yaoundeLat = 3.86667;
const yaoundeLong = 11.51667;
const AddPropertyForRent = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, locationData } = useSelector((state) => state?.user);
  const { currency } = useSelector((state) => state?.posts);
  const { email } = user || {};
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [validator, showValidationMessage] = useValidator();
  const [progress, setProgress] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(null);
  const [postData, setPostData] = useState({
    type: "",
    city: "",
    neighborhood: "",
    streetNumber: "",
    postalCode: "",
    price: "",
    category: "For Rent",
    bedrooms: "",
    numberOfToilets: "",
    numberAvailable: "",
    dining: "",
    cable: "",
    wifi: "",
    washingMachine: "",
    gym: "",
    swimmingPool: "",
    partySpace: "",
    parking: "",
    fenced: "",
    oneBalcony: "",
    twoBalconies: "",
    securityGuard: "",
    surveillanceCamera: "",
    wardrobe: "",
    electricity: "",
    water: "",
    normalCallNumber: "",
    airConditioner: "",
    camWater: "",
    generator: "",
    waterHeater: "",
    interphone: "",
  });
  const {
    type,
    city,
    neighborhood,
    price,
    category,
    numberOfToilets,
    numberAvailable,
    bedrooms,
    dining,
    parking,
    fenced,
    oneBalcony,
    twoBalconies,
    securityGuard,
    surveillanceCamera,
    wardrobe,
    electricity,
    water,
    normalCallNumber,
    camWater,
    generator,
    waterHeater,
    interphone,
    cable,
    wifi,
    washingMachine,
    gym,
    swimmingPool,
    partySpace,
    airConditioner,
  } = postData;
  const { country, citiesData, stateCode } = locationData;

  // console.log(postData);
  console.log(yaoundeLat, yaoundeLong);
  //console.log(userInfo.latitude, userInfo.longitude);


  const previous = () => {
    setStep((prev) => prev - 1);
  };

  const next = () => {
    setError("");
    showValidationMessage(false);
    if (validator.allValid() && previewImages.length >= 2) {
      setStep((prev) => prev + 1);
    } else {
      setError("upload error");
      showValidationMessage(true);
    }
  };
  const handlePhoneInputChange = (phone) => {
    if (email === "chebesiawah@gmail.com" && city === "") {
          dispatch(setLocationData(yaoundeLat, yaoundeLong));
        }
    setPhoneNumber(phone);
  };
  const removeImage = (index) => {
    const newPreviews = previewImages.filter((_, i) => i !== index);
    setPreviewImages(newPreviews);
  };
  const handleChange = (e) => {
    if (images.length >= 6) {
      dispatch(setCustomResponseMessage(t("image limit")));
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        setPreviewImages((prev) => [
          ...prev,
          URL.createObjectURL(e.target.files[i]),
        ]);
        const newImage = e.target.files[i];
        newImage["id"] = Math.random();
        setImages((prevState) => [...prevState, newImage]);
      }
    }
  };

  const handleAddProperty = async () => {
    setLoading(true);
    try {
      const promises = [];
      images.map((image) => {
        if (!image) {
          setError(t(error));
        } else {
          setError("");
          const storageRef = ref(storage, `/postImages/${image.name}`);

          const uploadTask = uploadBytesResumable(storageRef, image);
          promises.push(uploadTask);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(percent);
            },
            (err) => {
              const errorMessage = err.message;
              setError(errorMessage);
            },
            async () => {
              await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setUrls((prevState) => [...prevState, url]);
              });
            }
          );
        }
        return null;
      });

      await Promise.all(promises).then(() => {
        setMessage("upload success");
      });

      if (message === "upload success" && validator.allValid()) {
        // dispatch(createPostAction(postData,id,phoneNumber,urls,navigate))
        addDoc(collection(db, "apartments"), {
          timestamp: serverTimestamp(),
          imageUrls: urls,
          postedBy: user?.displayName,
          userId: user?.id,
          type: type,
          city: city,
          stateCode: stateCode,
          phoneNumber: phoneNumber,
          country: country,
          neighborhood: neighborhood,
          price: price,
          fenced: fenced,
          category: category,
          countryCurrency: currency,
          bedrooms: bedrooms,
          numberOfToilets: numberOfToilets,
          numberAvailable: numberAvailable,
          dining: dining,
          parking: parking,
          likes: 0,
          oneBalcony: oneBalcony,
          twoBalconies: twoBalconies,
          securityGuard: securityGuard,
          surveillanceCamera: surveillanceCamera,
          wardrobe: wardrobe,
          electricity: electricity,
          water: water,
          normalCallNumber: normalCallNumber,
          airConditioner: airConditioner,
          camWater: camWater,
          generator: generator,
          waterHeater: waterHeater,
          interphone: interphone,
          cable: cable,
          wifi: wifi,
          washingMachine: washingMachine,
          gym: gym,
          swimmingPool: swimmingPool,
          partySpace: partySpace,
          // videoUrl:videoLink
          // userProfileUrl: user?.photoURL,
        });
        setProgress(0);
        setPreviewImages(null);
        setPostData({
          type: "",
          city: "",
          neighborhood: "",
          streetNumber: "",
          postalCode: "",
          price: "",
          category: "For Rent",
          bedrooms: "",
          numberOfToilets: "",
          numberAvailable: "",
          dining: "",
          cable: "",
          wifi: "",
          washingMachine: "",
          gym: "",
          swimmingPool: "",
          partySpace: "",
          parking: "",
          fenced: "",
          oneBalcony: "",
          twoBalconies: "",
          securityGuard: "",
          surveillanceCamera: "",
          wardrobe: "",
          electricity: "",
          water: "",
          normalCallNumber: "",
          airConditioner: "",
          camWater: "",
          generator: "",
          waterHeater: "",
          interphone: "",
        });
        setLoading(false);
        navigate("/");
      } else {
        showValidationMessage(true);
        setLoading(false);
      }
    } catch (err) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPostData({ ...postData, [e.target.name]: value });
  };

  switch (step) {
    case 1:
      return (
        <ImagesSection
          nextStep={next}
          error={error} 
          previewImages={previewImages}
          handleImageChange={handleChange} 
          removeImage={removeImage}
          validator={validator}
          category="For Rent"
        />
      );
    case 2:
      return (
        <SectionOne
          nextStep={next}
          prevStep={previous}
          error={error}
          data={postData}
          validator={validator}
          inputChange={handleInputChange}
          progress={progress}
        />
      );

    case 3:
      return (
        <PropertyLocationSection
          nextStep={next}
          prevStep={previous}
          data={postData}
          citiesData={citiesData}
          validator={validator}
          inputChange={handleInputChange}
          phoneNumber={phoneNumber}
          handlePhoneInputChange={handlePhoneInputChange}
        />
      );
    case 4:
      return (
        <FacilitiesOne
          nextStep={next}
          prevStep={previous}
          inputChange={handleInputChange}
        />
      );
    case 5:
      return (
        <FacilitiesTwo
          nextStep={next}
          prevStep={previous}
          inputChange={handleInputChange}
        />
      );
    case 6:
      return (
        <FacilitiesThree
          submitForm={handleAddProperty}
          prevStep={previous}
          data={postData}
          inputChange={handleInputChange}
          error={error}
          loading={loading}
          message={message}
        />
      );
    default:
  }
};

export default AddPropertyForRent;
