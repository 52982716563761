import React, { useEffect} from 'react';

import './verification.css';
import { useTranslation} from 'react-i18next';
import { auth } from '../../../firebaseConfig';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const { t } = useTranslation();
  const { emailVerified } = auth.currentUser;
  const navigate = useNavigate()
console.log(emailVerified)

useEffect(() => {
  if(emailVerified === true){
    navigate("/login")
  }
}, [emailVerified,navigate])


  return (
   <div className="verify__container">
     
     <div className="body">
      <p className='text'>{t("verify")}</p>
      <span className="website__name">HOMESCRAPER Team</span>
     </div>
    
   </div>
  )
}

export default VerifyEmail
