import React, { useState } from "react";
import useValidator from "../../../../../utils/CustomValidator";
import { db, storage } from "../../../../../firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import {
  useDispatch,
  useSelector,
  //useDispatch
} from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCustomResponseMessage,
  setLocationData,
} from "../../../../../redux/actions";
import PropertyInfo from "./PropertyInfo";
import ImagesSection from "../ImagesSection";
import PropertyLocation from "./PropertyLocation";

const yaoundeLat = 3.86667;
const yaoundeLong = 11.51667;

const AddPropertyForSell = () => {
   const navigate = useNavigate();
     const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const { user, locationData } = useSelector((state) => state?.user);
  const { email } = user || {}
  const { currency } = useSelector((state) => state?.posts);
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [validator, showValidationMessage] = useValidator();
  const [progress, setProgress] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(null);
  const [postData, setPostData] = useState({
    type: "",
    city: "",
    neighborhood: "",
    price: "",
    dimension: "",
    category: "for sale",
    description: "",
  });
  const { type, city, neighborhood, price, dimension, category, description } =
    postData;
  const {country, citiesData,stateCode} = locationData;
  console.log(yaoundeLat, yaoundeLong);


  
   
  // useEffect(() => {
  //    const result = document
  //     .getElementById("phone__input")
  //     .getElementsByClassName("special-label");
  //   result[0].style.display = "none";
  // }, []);

  const handlePhoneInputChange = (phone) => {
    if (email === "chebesiawah@gmail.com" && city === "") {
      dispatch(setLocationData(yaoundeLat, yaoundeLong));
    }
    setPhoneNumber(phone);
  };

  const removeImage = (index) => {
    const newPreviews = previewImages.filter((_, i) => i !== index);
    setPreviewImages(newPreviews);
  };

  const handleImageChange = (e) => {
    if (images.length > 6) {
      dispatch(setCustomResponseMessage(t("image limit")));
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        setPreviewImages((prev) => [
          ...prev,
          URL.createObjectURL(e.target.files[i]),
        ]);
        const newImage = e.target.files[i];
        newImage["id"] = Math.random();
        setImages((prevState) => [...prevState, newImage]);
      }
    }
  };

  const handleAddProperty = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const promises = [];
      images.map((image) => {
        if (!image) {
          setError(t(error));
        } else {
          setError("");
          const storageRef = ref(storage, `/postImages/${image.name}`);

          const uploadTask = uploadBytesResumable(storageRef, image);
          promises.push(uploadTask);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(percent);
              console.log(progress)
            },
            (err) => {
              const errorMessage = err.message;
              setError(errorMessage);
            },
            async () => {
              await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setUrls((prevState) => [...prevState, url]);
              });
            }
          );
        }
        return null;
      });

      await Promise.all(promises).then(() => {
        setMessage("upload success");
      });

      if (message === "upload success" && validator.allValid()) {
        // dispatch(createPostAction(postData,id,phoneNumber,urls,navigate))
        addDoc(collection(db, "apartments"), {
          timestamp: serverTimestamp(),
          imageUrls: urls,
          postedBy: user?.displayName,
          userId: user?.id,
          type: type,
          city: city,
          phoneNumber: phoneNumber,
          country: country,
          stateCode:stateCode,
          neighborhood: neighborhood,
          price: price,
          category: category,
          dimension: dimension,
          description: description,
          countryCurrency:currency,
          likes: 0,
          // videoUrl:videoLink
          // userProfileUrl: user?.photoURL,
        });
        setProgress(0);
        setPreviewImages(null);
        setPostData({
          type: "",
          city: "",
          neighborhood: "",
          streetNumber: "",
          price: "",
          dimension: "",
          category: "for sale",
          description: "",
        });
        setLoading(false);
        navigate("/for-sale");
      } else {
        showValidationMessage(true);
        setLoading(false);
      }
    } catch (err) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPostData({ ...postData, [e.target.name]: value });
  };

  const previous = () => {
    setStep((prev) => prev - 1);
  };


  const next = () => {
    setError("");
    showValidationMessage(false);
    if (validator.allValid() && previewImages.length >= 1) {
      setStep((prev) => prev + 1);
    } else {
      setError("upload error");
      showValidationMessage(true);
    }
  };

  switch (step) {
    case 1:
      return(
        <ImagesSection
        nextStep={next}
          error={error} 
          previewImages={previewImages}
          handleImageChange={handleImageChange} 
          removeImage={removeImage}
          validator={validator}
          category="For Sale"
        />
        
      )
    case 2:
      return(
        <PropertyInfo
         nextStep={next}
          prevStep={previous}
          error={error}
          data={postData}
          validator={validator}
          inputChange={handleInputChange}
          progress={progress}
        />
      )
    case 3:
      return(
        <PropertyLocation
        nextStep={next}
        prevStep={previous}
        data={postData}
        message={message}
        citiesData={citiesData}
        validator={validator}
        inputChange={handleInputChange}
        phoneNumber={phoneNumber}
        handlePhoneInputChange={handlePhoneInputChange}
        handleAddProperty={handleAddProperty}
        loading={loading}
        />
      )
    default:
  }
  
};

export default AddPropertyForSell;
