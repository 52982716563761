
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { auth } from "../../firebaseConfig";
const redirectPath2 = "/verify-email"
const redirectPath3 = "/subscribe"
const ProtectedRoute = ({
  redirectPath = "/"
  
}) => {
  const { user } = useSelector((state) => state.user)
  const {  refreshedUser } = useSelector((state) => state.posts)
  // const { id,isSubscribed,displayName } = user?.user || {};
  const { displayName  } = user || {};
  const { isSubscribed  } = refreshedUser || {};
const { emailVerified,email } = auth.currentUser || {}




  
 
   if ((email && emailVerified === false) && !displayName) {
    return <Navigate to={redirectPath2} />;
  }else if ( displayName && isSubscribed === false) {
    return <Navigate to={redirectPath3} />
  }else if (!displayName) {
    return <Navigate to={redirectPath} />;
  }
  return <Outlet />;
};
export default ProtectedRoute;
