import React from 'react'
import { useTranslation } from "react-i18next";

const CustomInputField = ({inputType,value,handleInputChange,placeholderText,inputName,inputRef,validator}) => {
      const { t } = useTranslation();
  return (
    <>
        <div className='custom__input m-0'>
        <input
        autoComplete='off'
            type={inputType}
            placeholder={placeholderText}
            name={inputName}
            value={value}
            onChange={handleInputChange}
            className="w-100"
            ref={inputRef}
            required
          />
    </div>
    <label className="text-danger text-left w-100">
            {validator?.message(inputName, value, `required|${inputName}`, {
              messages: {
                required: t("required"),
              },
            })}
          </label>
    </>
  )
}

export default CustomInputField

// Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate quidem doloribus consequatur aperiam dolorem perspiciatis dicta officiis labore laudantium nulla qui, quam quae non? Numquam totam atque earum magnam. Repellendus natus, soluta reprehenderit explicabo delectus expedita. Delectus nobis voluptatum odio ipsam perferendis commodi incidunt consequuntur earum! Fugit ab dolorem alias? Dignissimos ad, perspiciatis eum incidunt ratione quia ullam magni totam animi sed nulla ipsa ipsam hic tempora perferendis eos corporis maxime? Nesciunt at officiis illo doloribus mollitia deleniti vitae perspiciatis.