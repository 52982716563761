import React, { useCallback } from 'react'
// import { MdOutlineSort } from 'react-icons/md';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import './search.css';
import { useTranslation } from 'react-i18next';
import { IoSearch } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import { today } from '../../utils/utils';
import { useSelector,useDispatch } from 'react-redux';
import { searchAction, setSearchTerm } from '../../redux/actions';




const Search = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { subscriptionExpiryDate,searchTerm,posts } = useSelector((state) => state.posts)
  const dispatch = useDispatch()


   const handleSearch = useCallback(
      (e) => {
        e.preventDefault();
  
        if (window.location.href !== "/") {
          navigate('/')
        }
        dispatch(searchAction({posts, searchTerm, today, subscriptionExpiryDate, navigate}))
      }, [navigate, dispatch, posts, searchTerm, subscriptionExpiryDate])

  const handleChange = (e) => {
    // if(!subscriptionExpiryDate || today > subscriptionExpiryDate){
    //   navigate("/subscribe")
    //     }
   dispatch(setSearchTerm(e))
  }
    //  const handleSearch = (e) => {
    //   e.preventDefault();
    //   navigate('/')
    //   const searchValue = e.target.value;
    // setSearchTerm(searchValue)
    // dispatch(searchAction(posts,searchTerm))
    // setSearchTerm("")
      
    //  }
   
  return (
    <div className="header__searchBox">
     <div className="header__search1">
     <form  onSubmit={handleSearch} className='input'>
      <IoSearch className='location' size={25} color='#fff'/>
      <input
      type='text'
      className='search__input'
       margin="normal"
        placeholder={t('search')}
        value={searchTerm}
        onChange={handleChange}
      />
      </form>
     </div>   
    </div>
    
  )
}

export default Search

