import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { loader } from "../../../../Assets";
import { Loader, FavoritePostCard } from "../../../../components";
import "./favorites.css";

const Favorites = () => {
  const { favorites } = useSelector((state) => state.posts);
  const { t } = useTranslation();

  return (
    <div className="favorites__screen w-100">
      <div className="favorites__header">
        <h3 className="favorites__header-text">{t("saved properties")}</h3>
      </div>
      <div className="favorites__postsContainer text-secondary">
        <Suspense
          fallback={<img src={loader} alt="" className="loading__image" />}
        />

        <div className="favorites__fallback">
          {favorites?.length === 0 && <p>{t("saved properties notice")}</p>}
        </div>
        <Suspense fallback={<Loader />}>
          {favorites?.map((post) => (
            <FavoritePostCard item={post} key={post?.id} />
          ))}
        </Suspense>
      </div>
    </div>
  );
};

export default Favorites;
