import React from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { switchToNextPage, switchToPreviousPage } from "../../redux/actions";

const PaginationContainer = () => {
  const { currentPage,numOfPages } = useSelector((state) => state.posts)
  const dispatch = useDispatch()
  // const pageNumbers = [...Array(nPages + 1).keys()].slice(1);



  const goToNextPage = () => {
    dispatch(switchToNextPage({currentPage,numOfPages}))
  };

  const goToPrevPage = () => {
    dispatch(switchToPreviousPage(currentPage))
  };

  return (
    <div className="pagination_buttons">
      <button
        className="bg-light"
        disabled={currentPage === 1 ? true : false}
        style={{
          backgroundColor: "5D9CEC",
          padding: "3px 10px",
          border: "none",
        }}
        onClick={goToPrevPage}
      >
        Previous
      </button>
      {/* {pageNumbers.map((pgNumber) => ( */}
      <button
        className={`page-item active `}
        //   className={`page-item ${currentPage === pgNumber ? "active" : ""} `}
        // key={pgNumber}
        style={{
          backgroundColor: "rgb(35, 183, 229)",
          padding: "3px 10px",
          border: "none",
        }}
        // onClick={() => setCurrentPage(pgNumber)}
      >
        {currentPage}
      </button>
      {/* ))} */}
      <button
        className="bg-light"
        disabled={numOfPages === 0 ? true : false}
        style={{
          backgroundColor: "5D9CEC",
          padding: "3px 10px",
          border: "none",
        }}
        onClick={goToNextPage}
      >
        Next
      </button>
    </div>
  );
};

export default PaginationContainer;
