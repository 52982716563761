import React from "react";
import "./createPost.css";
import { useTranslation } from "react-i18next";
import { CustomInputField, CustomSelectField,Buttons } from "../../../../../components";
import { apartmentTypes } from "../../../../../utils/utils";

const SectionOne = ({
  data,
  inputChange,
  nextStep,
 prevStep,
  validator,

}) => {
  const { t } = useTranslation();

  return (
    <div className="createPost__container">
      <form onSubmit={nextStep} className="createPost__form">
        <CustomSelectField
          labelId="type-select"
          selectId="typeSelect"
          labelName={t("select type")}
          inputName="type"
          value={data.type}
          handleInputChange={inputChange}
          data={apartmentTypes}
          validator={validator}
        />
        <CustomInputField
          inputType="number"
          inputName="price"
          placeholderText={t("price")}
          value={data.price}
          handleInputChange={inputChange}
          validator={validator}
        />
        <CustomInputField
          inputType="number"
          inputName="numberAvailable"
          placeholderText={t("no available")}
          value={data?.numberAvailable}
          handleInputChange={inputChange}
          validator={validator}
        />
        {(data?.type === "simple apartment" ||
          data?.type === "flat" ||
          data?.type === "guest house") && (
          <>
            <CustomInputField
              inputType="number"
              inputName="bedrooms"
              placeholderText={t("bedrooms")}
              value={data.bedrooms}
              handleInputChange={inputChange}
              validator={validator}
            />
            <CustomInputField
              inputType="number"
              inputName="numberOfToilets"
              placeholderText={t("bathrooms")}
              value={data.numberOfToilets}
              handleInputChange={inputChange}
              validator={validator}
            />
          </>
        )}

        <div className="submit__container">
          <Buttons next={nextStep} previous={prevStep}/>
        </div>
      </form>
    </div>
  );
};

export default SectionOne;
