import React, { useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import "./styles.css";
import Pagination from "../Pagination/PaginationContainer";
import { Link } from "react-router-dom";
import { TiEdit } from "react-icons/ti";
import { doc,deleteDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useSelector,useDispatch } from "react-redux";
import { getPaginatedPostsRecord } from "../../redux/actions";

const PostsTableCard = ({ columns, sortedPosts,userId }) => {
  const {  currentPage,
    paginatedPosts,
    numPerPage,
    numOfPages, } = useSelector((state) => state.posts)
  const dispatch = useDispatch()


   useEffect(() => {
      dispatch(getPaginatedPostsRecord({ currentPage, numPerPage, sortedPosts }));
    }, [dispatch, sortedPosts, currentPage, numOfPages, numPerPage]);

  const handleDelete = async (id) => {
    const postDocRef = doc(db, "apartments", id);
    try {
      await deleteDoc(postDocRef);
    } catch (err) {
      alert(err);
    }
  };



  return (
    <div
      className="w-100"
    >
      <div className="">
        <table className="table">
          <thead className="">
            <tr>
              {columns.map((col,index) => (
                <th
                  scope="col"
                  style={{
                    fontSize: "14px",
                    color: "#656565",
                    fontWeight: "500",
                  }}
                  key={index}
                >
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedPosts?.map((item) => (
              <tr key={item?.id}>
                <td className="cell__item">{item.post?.type}</td>
                <td className="cell__item">{item.post?.price}</td>
                <td className="cell__item">{item.post.city}</td>
                {/* <td className="cell__item">{item.post?.numberOfToilets}</td>
                <td className="cell__item">{item.post?.bedrooms}</td> */}
                <td className="cell__item">{item.post?.numberAvailable}</td>
                <td>
                  <div className="d-flex justify-content-start align-items-center">
                    <span className="mail mx-1">
                      <Link to={`/${userId}/edit-post/${item?.id}`} state={item}>
                        <TiEdit size={20} color="white" />
                      </Link>
                    </span>
                    <span className="delete mx-1">
                      <RiDeleteBinLine
                        size={20}
                        color="white"
                        onClick={() => handleDelete(item?.id)}
                      />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <br />
      <div className="d-flex justify-content-between align-items-center w-100 px-4">
        <div className="">
          <p className="pagination_note">
            Showing Page <span>{currentPage}</span> of{" "}
            <span>{numOfPages} pages</span>
          </p>
        </div>
        <Pagination
         
        />
      </div>
    </div>
  );
};

export default PostsTableCard;
