import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./postCard.css";
import { IoLocationOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PaidIcon from "@mui/icons-material/Paid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TerrainIcon from "@mui/icons-material/Terrain";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { Heart } from "react-feather";
import {
  getUsersWhoSavedToFavoritesAction,
  toggleSaveFavoritesAction,
} from "../../redux/actions";
import { ToastContainer, toast } from "react-toastify";
import {
  dateConverter,
  //getSavedData
} from "../../utils/utils";
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";

//import { profile } from "../../Assets";

const PostCard = (post) => {
  const { item, id } = post;
  const { user } = useSelector((state) => state?.user);
  const { saveNotification, currency } = useSelector((state) => state.posts);
  const savedBy = JSON.parse(localStorage.getItem("saver"));
  const [isSavedToFavorites, setIsSavedToFavorites] = useState(false);
  const dispatch = useDispatch();
  const { role, displayName } = user || {};
  const { t } = useTranslation();
  const currentUserId = user?.id;

  const {
    type,
    city,
    neighborhood,
    price,
    countryCurrency,
    imageUrls,
    timestamp,
    category,
    dimension,
    // postedBy,
    numberAvailable,
    likes,
  } = item?.post;

  //const imageIndex = imageUrls?.length;
  const finalDate = dateConverter(timestamp);

  useEffect(() => {
    if (currentUserId) {
      savedBy?.forEach((item) => {
        if (item?.saver?.match(currentUserId) && item?.post?.match(id)) {
          setIsSavedToFavorites(true);
        }
      });
    }
  }, [savedBy, currentUserId, id, likes]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(getUsersWhoSavedToFavoritesAction(id, currentUserId));
    }

    // eslint-disable-next-line
  }, [dispatch, currentUserId, id]);

  // useEffect(() => {
  //   if(savedBy?.indexOf(currentUserId) !== -1){
  //     setToggleSaved(true)

  //   }
  // },[savedBy,currentUserId,displayName])

  const toggleSave = async () => {
    if (!displayName) return;
    if (!isSavedToFavorites) {
      dispatch(toggleSaveFavoritesAction(item?.post, id, currentUserId));
      toast.info(t(saveNotification), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div className="post__container m-2">
      <Link className="post__link" to={`/post/${id}`} state={item}>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="postCard__image">
          <div className="postCard__header pt-2">
            <h5 className="city__container-head">
              <IoLocationOutline size={13} color="#4db5ff" />
              <span className="city">{city}</span>
            </h5>
            {category === "For Rent" && (
              <h5 className="bedrooms">
                {t("available")}: {t(numberAvailable)}
              </h5>
            )}
          </div>
          <div className="postCard__image-container">
            <img src={imageUrls[1]} alt="post card" loading="lazy" />
          </div>
        </div>
        <div className="type">
          <span className=""></span> {t(city.toUpperCase())}
        </div>
        <div className="postCard__body">
          <div className="d-flex justify-content-start flex-column align-items-start">
            <div className="d-flex justify-content-start align-items-center gap-1 mb-1 w-100">
              <MapsHomeWorkIcon size="medium" color="primary" />
              <h5 className="post__price w-100"> {t(type)}</h5>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-1 mb-1 w-100">
              <LocationOnIcon size="medium" color="primary" />
              <h5 className="post__price w-100"> {neighborhood}</h5>
            </div>
            {dimension && (
              <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
                <TerrainIcon size="medium" color="inherit" />
                <h5 className="post__price">
                  {dimension}/M<sup>2</sup>
                </h5>
              </div>
            )}
            <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
              <PaidIcon size="medium" color="primary" />
              <h5 className="post__price">
                <strong>
                  {price} {!countryCurrency ? currency : countryCurrency}
                  {t(category.toLowerCase()) === t("For Rent") &&
                  t(type) !== t("guest house")
                    ? t("per month")
                    : t(category.toLowerCase()) === t("For Rent") &&
                      t(type) === t("guest house")
                    ? t("per night")
                    : ""}
                </strong>
              </h5>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-1 mb-1">
              <ScheduleIcon size="medium" color="disabled" />
              <h5 className="subtitle">Posted {finalDate}</h5>
            </div>
          </div>
          <div className="avatar__button">
            {/* <h5  className="postedBy">
              { postedBy}
            </h5>{" "} */}
            {(role === "client" || !role) && (
              <div className="post__bottom-container">
                <div className="post__save-container">
                  <div className="post__save">
                    <Heart
                      size={20}
                      color={isSavedToFavorites ? "#EC607F" : "#000"}
                      className="post__like-icon"
                      onClick={toggleSave}
                    />
                    <span>{likes}</span>
                    {/* <i class="fa fa-share-square" aria-hidden="true"></i> */}
                  </div>
                </div>
              </div>
            )}
            {user?.email === "chebesiawah@gmail.com" && (
              <FacebookShareButton
                url="https://www.homescraper.com/post/3lZHoHW67Xycgd1O13eT"
                quote="Homescraper - All Rental Property in one place"
                title="Another One!"
                hashtag="#homescraper"
                className="facebook__share-button"
              >
                <FacebookIcon size={23} round />
                share
              </FacebookShareButton>
            )}
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          theme="colored"
        />
      </Link>
    </div>
  );
};
export default PostCard;
