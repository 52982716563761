import { noNetworkImage } from '../../Assets';
import './styles.css'
import { useTranslation } from 'react-i18next';



const NoPropertyFallback = () => {
    const { t } = useTranslation();
 return (
    <main className="fallback">
      <div className="">
      <img src={noNetworkImage} alt="" height="100px" />
      </div>
    <div className="home__postsContainer">
      <h2 className="fallback-message">{t("fallback")}</h2>
      </div>
    </main>
  )}


export default NoPropertyFallback