export const GET_SAVED_BY = "GET_SAVED_BY";
export const REMOVE_SAVED_BY = "REMOVE_SAVED_BY";

export const CREATE_NEW_POST = "CREATE_NEW_POST";
export const DELETE_POST = "DELETE_POST";
export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const GET_FAVORITES = "GET_FAVORITES";
export const GET_ALL_RENTS_POSTS = "GET_ALL_RENTS_POSTS";
export const GET_ALL_SALE_POSTS = "GET_ALL_SALE_POSTS";
export const REMOVE_FROM_FAVORITES = "REMOVE_FROM_FAVORITES";
export const SAVED_TO_FAVORITES = "SAVED_TO_FAVORITES";
export const START_LOADING = "START_LOADING";
export const START_LOADING_SEARCH = "START_LOADING_SEARCH";
export const END_LOADING = "END_LOADING";
export const START_AUTH_LOADING = "START_AUTH_LOADING";
export const END_AUTH_LOADING = "END_AUTH_LOADING";
export const LOG_IN_USER = "LOG_IN_USER";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const SET_LOGIN_MESSAGE = "SET_LOGIN_MESSAGE";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SUBSCRIBE_USER = "SUBSCRIBE_USER";
export const UNSUBSCRIBE_USER = "UNSUBSCRIBE_USER";
export const GET_SUBSCRIPTION_EXPIRY_DATE = "GET_SUBSCRIPTION_EXPIRY_DATE";
export const REFRESH_USER = "REFRESH_USER";
export const GET_SUBSCRIPTION_EXPIRY_DATE_ID =
  "GET_SUBSCRIPTION_EXPIRY_DATE_ID";
export const SET_CURRENT_COORDS = "SET_CURRENT_COORDS";
export const SET_CUSTOM_RESPONSE_MESSAGE = "SET_CUSTOM_RESPONSE_MESSAGE";
export const SET_LOCATION_DATA = "SET_LOCATION_DATA";
export const HANDLE_SEARCH_INPUT_CHANGE = "HANDLE_SEARCH_INPUT_CHANGE";
export const HANDLE_CLEAR_INPUT = "HANDLE_CLEAR_INPUT";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
export const SET_SECTION = "SET_SECTION";
export const SET_FILTER_INPUT_VALUE = "SET_FILTER_INPUT_VALUE";
export const SET_TAB = "SET_TAB";
export const SWITCH_PAGE = "SWITCH_PAGE";
export const SET_PAGINATION_DATA = "SET_PAGINATION_DATA";
export const SET_SALE_PAGINATION_DATA = "SET_SALE_PAGINATION_DATA";
export const SET_SORTED_POST = "SET_SORTED_POST";
export const SET_SORTED_SALE_POST = "SET_SORTED_SALE_POST";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_CONVERTED_PRICE = "SET_CONVERTED_PRICE";
export const SET_CITY_INPUT_VALUE = "SET_CITY_INPUT_VALUE";
export const SET_ALL_TOWNS = "SET_ALL_TOWNS";
export const GET_GEOLOCATION_DATA = "GET_GEOLOCATION_DATA";
export const GET_CMR_GEOLOCATION_DATA = "GET_CMR_GEOLOCATION_DATA";
export const TOGGLE_ACTIVE_MENU = "TOGGLE_ACTIVE_MENU";
