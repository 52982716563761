import React, { useState, useEffect } from "react";
import MyPosts from "./MyProperties/MyProperties";

const Profile = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [screenSize, setScreenSize] = useState(true);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  return (
    <div className="sidebar__wrapper">
      <div className={activeMenu ? "dashboard__active" : "dashboard"}>
        <MyPosts />
      </div>
    </div>
  );
};

export default Profile;
