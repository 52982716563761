import { useNavigate } from 'react-router-dom';
import './styles.css'
import { useTranslation } from 'react-i18next';
import { noNetworkImage } from '../../Assets';



const NoInternetFallback = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const reload = () => {
      navigate("/")
   // window.location.reload()
    }
 return (
    <main className="fallback">
    <div className="fallback__container">
      <div className="">
      <img src={noNetworkImage} alt="" height="100px" />
      </div>
      <h3 className="fallback__message">{t("no internet")}</h3>
      <div>
        <button type="button" className='back__button' onClick={reload}>{t("reload page")}</button>
      </div>
      </div>
    </main>
  )}


export default NoInternetFallback