import { Timestamp, getDocs } from "firebase/firestore";
import moment from "moment";
import { City } from "country-state-city";
import { doc, getDoc, collection, query } from "firebase/firestore";
import axios from "axios"
import { auth, db } from "../firebaseConfig";
export const roles = ["client","agent"]
  export const apartmentTypes = ["modern studio","modern room","guest house","simple apartment","flat"]
export const phoneNumberInputStyles = {
  width: "100%",
  height: "45px",
  borderRadius: "7px",
  outline: "none",
  margin: "10px 0",
  backgroundColor: "transparent",
  border: "1px solid #4db5ff",
  color:"#4db5ff",
  Padding: "10px 0",
};

function getBrowserLocales(options = {}) {

  const defaultOptions = {

    languageCodeOnly: false,

  };

  const opt = {

    ...defaultOptions,

    ...options,

  };

  const browserLocales =

    navigator.languages === undefined

      ? [navigator.language]

      : navigator.languages;

  if (!browserLocales) {

    return undefined;

  }

  return browserLocales.map(locale => {

    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly

      ? trimmedLocale.split(/-|_/)[0]

      : trimmedLocale;

  });

}
const cities = ["Yaounde","buea","Douala"]
console.log(cities.indexOf("Bamenda"))
const lang = getBrowserLocales({languageCodeOnly: true})
console.log(lang)
const currentLanguage = window.navigator.languages
console.log(currentLanguage)
export const LANGUAGES = [
  { label: lang[0], code: lang[0] },
  { label: lang[1], code: lang[1]},
  // { label: "Arabic", code: "ar" },
  // { label: "Spanish", code: "es" },
  // { label: "Portogues", code: "es" },
];

export const today = new Date();
export function dateConverter(timestamp) {
  const fieldVal = timestamp;
  const timeStamp = new Timestamp(fieldVal?.seconds, fieldVal?.nanoseconds);
  const date = timeStamp.toDate();
  const finalDate = moment(date).startOf("second").fromNow();

  return finalDate;
}
export function expiryDateConverter(timestamp) {
  const fieldVal = timestamp;
  const timeStamp = new Timestamp(fieldVal?.seconds, fieldVal?.nanoseconds);
  const date = timeStamp.toDate();
  return date;
}

export function userJoinedDateConverter(joinedOn) {
  const fieldVal = joinedOn;
  const timeStamp = new Timestamp(fieldVal?.seconds, fieldVal?.nanoseconds);
  const date = timeStamp.toDate();
  const finalDate = moment(date.setDate(date.getDate() + 30));
  // const finalDate = moment(date).startOf("second").fromNow();
  return finalDate;
}

export function finalDateConverter(todayDate) {
  const finalDate = moment(todayDate).startOf("second").fromNow();
  return finalDate;
}

export async function getSubscriptionExpiryData(userId) {
  const data = query(collection(db, `users/${userId}/subscriptionExpiryDate`));
  let newData;
 try {
  const snapshots = await getDocs(data);
   newData = snapshots.docs.map((doc) => ({
    id: doc.id,
    expiry: doc.data(),
  }));
 } catch (error) {
  console.log(error)
 }
  return newData;
}
getSubscriptionExpiryData();
export function getSubscriptionExpiryDate(arr) {
  const date = arr?.map(({ expiry }) => expiry);
  let finalDate;
  for (let i = 0; i < date?.length; i++) {
    finalDate = expiryDateConverter(date[i]?.expiryDate);
  }
  return finalDate;
}
export function getSubscriptionExpiryDateId(arr) {
  let finalId;
  const id = arr?.map(({ id }) => id);

  for (let i = 0; i < id?.length; i++) {
    finalId = id[i];
  }
  return finalId;
}

// const userId = 'j0fcc1UvWWSQD0ra506gLbhgwUB3'
// const postId = "zQ1x41RNvCshIExpJxNY"
// const currentData = query(collection(db, `/favorites/${postId}/savedBy`));

export async function getCurrentlySavedData(metaData) {
  const snapshots = await getDocs(metaData);
  const newData = snapshots.docs.map((doc) => ({
    id: doc.id,
    item: doc.data(),
  }));
  //console.log(newData)
  return newData;
}

// async function testing(){
//   const currentlySavedBy = await getCurrentlySavedData(currentData)
//   console.log(currentlySavedBy)
//   const currentSaver = getCurrentSaver(currentlySavedBy,userId);
//              console.log(currentSaver)

// }
// testing()

export function getCurrentSaver(arr, userId,postId) {
  let savedByArr = [];
  let savedBy;

  for (let i = 0; i < arr?.length; i++) {
     if (arr[i]?.item?.savedBy === userId){
       console.log(`its a match ${arr[i]?.item?.savedBy}`)
       const saverObj = {saver:arr[i]?.item?.savedBy,post:postId}
       savedBy = arr[i]?.item?.savedBy
      savedByArr.push(saverObj);
      localStorage.setItem("saver",JSON.stringify(savedByArr))
     }
   
      //console.log(savedBy)
   
  }
  return [savedBy,savedByArr];
}

// const userId = 'j0fcc1UvWWSQD0ra506gLbhgwUB3'
// const postId = "zQ1x41RNvCshIExpJxNY"
// const currentData = query(collection(db, `/favorites/${postId}/savedBy`));

// getFavoriteSavedBy(currentData,userId)

export function getCurrentSaverId(arr, userId) {
  let finalId;

  for (let i = 0; i < arr?.length; i++) {
    if (arr[i]?.item?.savedBy === userId) finalId = arr[i]?.id;
    // console.log(finalId)
  }
  return finalId;
}
export function getSavedItemId(arr, postId) {
  let favoriteId;
  //console.log(arr, postId);
  for (let i = 0; i < arr?.length; i++) {
    if (arr[i]?.item?.id === postId) favoriteId = arr[i]?.id;
  }
  return favoriteId;
}

export async function refreshUser() {
  const colRef = collection(db, "users");
  const user = await getDoc(doc(colRef, auth.currentUser?.uid)).then(
    (snapshot) => {
      return snapshot.data();
    }
  );
  // console.log(user)
  return user;
}

export async function convertCurrency(countryCurrency,currency,price){
  console.log(countryCurrency,currency,price)
  const currentCurrency = currency.toUpperCase()
  const postCurrency = !countryCurrency?.toUpperCase() ?  "XAF" : countryCurrency?.toUpperCase()
  console.log(postCurrency,currentCurrency,price)
 const convertedPrice = await axios.get(
    `https://v6.exchangerate-api.com/v6/e8116993e8318defdda6a8c8/latest/${postCurrency}`)
                .then(async(res) => {
                   console.log(res);
                  const info = res.data?.conversion_rates
                    const rate = info[currentCurrency]
                    let newPrice = await Math.round(price * rate )
                    return newPrice;
                })
                 console.log(convertedPrice);
return  convertedPrice

}
//convertCurrency(undefined,"usd",600000)
const NOMATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";

export const getCoordinates = async (neighborhood, city) => {
  console.log(neighborhood, city)
  let data;
  const params = {
    q: neighborhood,
    format: "json",
    addressdetails: 1,
    polygon_geojson: 0,
  };
  const queryString = new URLSearchParams(params).toString();
  const response = await fetch(`${NOMATIM_BASE_URL}${queryString}`);
  console.log(response)
  const result = await response.json();
  console.log(result)
  result?.forEach((res) => {
    if (
      res?.address?.suburb?.match(neighborhood) ||
      res?.address?.city?.match(city)
    ) {
      // console.log(res)
      data = res;
    }
  });

  return data;
};
//KIT302891252
export const getCurrentCoordinatesAndLocation = async (lat, lon) => {
  let finalData;
  //console.log(lat,lon)
  const params = {
    format: "json",
    addressdetails: 1,
  };
  const queryString = new URLSearchParams(params).toString();
 
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&${queryString}`
    );
    const result = await response.json();
    const boundingBox = result?.boundingbox;
    const data = result?.address;
    const { country_code, country, city, suburb } = data || {};
    const countryCode = data  ? data["ISO3166-2-lvl4"]?.slice(0, 2) : null;
    const stateCode = data ? data["ISO3166-2-lvl4"]?.slice(3) : null;


    const citiesData = City.getCitiesOfState(countryCode, stateCode).map(
      (city) => city.name
    );
     finalData = {
      country_code,
      country,
      citiesData,
      stateCode,
      city,
      boundingBox,
      suburb,}
  } catch (err){
    console.log(err)
   
//  dispatch(setCustomResponseMessage(`${err.message} Please check your network connection`))
  }

  return finalData;
};

export async function testGeoCode(lat, lon) {
  const response = await fetch(
    `https://geocode.maps.co/reverse?lat=${lat}&lon=${lon}&api_key=${process.env.REACT_APP_GEO_CODE_API_KEY}`
  );
  const result = await response.json();
  // console.log(result)
  return result;
}
//https://overpass-api.de/api/map?bbox=9.5296,4.0071,9.9862,4.2927
//https://api.openstreetmap.org/api/0.6/trackpoints?bbox=0,51.5,0.25,51.75&page=0
export async function testBoundingBox(box) {
  const response = await fetch(
    `https://api.openstreetmap.org/api/0.6/map?bbox=${box[0]},${box[1]},${box[2]},${box[3]}&page=1`
  );
  const result = await response.json();
  console.log(result);
}


export function googleMapApiPrompt(){

    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      // use the latitude and longitude to get the user's address
      try {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA7cBmAOfo6opESilIUNy8Vzvsc5mPdxIc`)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          console.log(data)
        });
      } catch (error) {
        console.log(error)
      }
    });

}