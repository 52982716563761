import React, { useState } from 'react'
import './subscription.css'
import { useDispatch, useSelector } from 'react-redux'
import { monthlySubscriptionAction } from '../../redux/actions'
import { 
  //Link,
   useNavigate } from 'react-router-dom'
   import { useTranslation } from 'react-i18next';
import { loader } from '../../Assets'

const Subscription = () => {
  const { t } = useTranslation();
  const [userType, setUserType] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state?.user)
  const { subscriptionExpiryDateId,isLoading} = useSelector((state) => state?.posts)
  const { id, isSubscribed,isTrialDone,role } = user || {}


  const subscribe = () => {
    if (!id) {
      navigate("/register");
    }else{
      dispatch(monthlySubscriptionAction(isSubscribed,isTrialDone,subscriptionExpiryDateId,role,navigate))
    }

  }
  return (
    <div className='subscription'>
        <div className="subscription__header1">
            <h2>{t("choose plan")}</h2>
           
          <select
            className="select"
            labelid="quality-select"
            id="qualitySelect"
            label={t("choose role")}
            name="userType"
            onChange={(e) => setUserType(e.target.value)}
            value={userType}
          >
           {
            userType === "" && (
              <option >{t("choose role")}</option>
            )
           }
            <option value="agent">{t("agent")}</option>
            <option value="client">{t("client")}</option>
          </select>
      
        </div>
        <div className="subscription__options">
        {/* {
          (!isTrialDone || undefined) && ( */}
            <div className="subscription__option">
        <div className="subscription__header">
            <h2>{ (userType === "agent" ) ? t("agent trial") : t("client trial")}</h2>
        </div>
       <div className='subscription__message'>
        <p>🚀 {t("item 1")}</p>
        <p>🚀 {t("item 2")}</p>
        <p>🚀 {t("item 3")} </p>
        <p>🚀 {t("item 4")} </p>
        <p style={{ borderBottom: "1px solid #96949480"}}>🚀 {t("item 5")} </p>
       </div>
     
        <div className="subscription__button" onClick={subscribe}>{t("free trial button")}</div>
        {isLoading && <img src={loader} alt="" className="loading__image" />}
        </div>
       
        {/* <div className="subscription__option">
        <div className="subscription__header">
            <h2>{ userType === "client" ? "3000" + t("monthly sub") : "2500" + t("monthly sub")}</h2>
        </div>
       <div className='subscription__message'>
        <p>🚀 {t("item 1")}</p>
        <p>🚀 {t("item 2")}</p>
        <p>🚀 {t("item 3")} </p>
        <p>🚀 {t("item 4")} </p>
        <p style={{ borderBottom: "1px solid #96949480"}}>🚀 {t("item 5")} </p>
       </div>
      {
        isTrialDone &&  <Link className="subscription__button" aria-disabled to='/checkout' onClick={subscribe}>proceed to checkout</Link>
      }
        </div> */}
            {/* {
              userType === "agent" && (
                <div className="subscription__option">
        <div className="subscription__header">
            <h2>{t("yearly sub")}</h2>
        </div>
       <div className='subscription__message'>
        <p>🚀 {t("item 1")}</p>
        <p>🚀 {t("item 2")}</p>
        <p>🚀 {t("item 3")} </p>
        <p>🚀 {t("item 4")} </p>
        <p style={{ borderBottom: "1px solid #96949480"}}>🚀 {t("item 5")} </p>
       </div>
       {
        isTrialDone &&  <Link className="subscription__button" aria-disabled to='/checkout' onClick={subscribe}>proceed to checkout</Link>
      }
        </div>
              )
            } */}
        </div>
    </div>
  )
}

export default Subscription