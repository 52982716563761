import React, { Suspense, useEffect } from "react";
import { loader } from "../../../Assets";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { PostCard, Loader, PaginationContainer } from "../../../components";
import { getPaginatedPostsRecord, resetCurrentPage } from "../../../redux/actions";


const AllRentalProperties = () => {
    const { t } = useTranslation();
  const {
    isSearchLoading,
    sortedPosts,
    currentPage,
    paginatedPosts,
    numPerPage,
    numOfPages,
  } = useSelector((state) => state.posts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCurrentPage())
    dispatch(getPaginatedPostsRecord({ currentPage, numPerPage, sortedPosts }));
  }, [dispatch, sortedPosts, currentPage, numOfPages, numPerPage]);


  // if (posts.length === 0) {
  //   return <Loader />;
  // }
  return (
    <Suspense fallback={<Loader />}>
      <main className="home__screen" id="top">
        <Helmet>
          <title>Real Estate & Homes for Sale in Cameroon,Rentals,Guest House</title>
          <meta
            name="description"
             content="Find guest houses for breakfast and bed (B&B), houses to rent,houses and plots of land for sell,and solve all your real estate problems.We have brought thousands of homes,plots of land,guest houses and real estate agents closer to you.From the comfort of your home and with your phone or laptop,access our thousand of property and speak with real estate agents with one click"
          />
          <meta
            name="keywords"
            content="real estate in Cameroon, homes for sale in Cameroon,rentals,guest house "
          />
        </Helmet>

        <div className="home__postsContainer text-secondary">
          {isSearchLoading && (
            <Suspense
              fallback={<img src={loader} alt="" className="loading__image" />}
            />
          )}

          <div className="posts__container-items">
            {paginatedPosts?.map((post) => (
              <PostCard item={post} id={post?.id} key={post?.id} />
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center w-100 px-4">
          <div className="">
            <p className="pagination_note">
            {t("current page")} <span>{currentPage}{" "}</span>/{" "}
              <span>{numOfPages}</span>
            </p>
          </div>
          <PaginationContainer />
        </div>
      </main>
    </Suspense>
  );
};

export default AllRentalProperties;
