import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./filterStyles.css";
import {
  handleCityInputChange,
  handleFilterInputChange,
  resetCurrentPage,
  setCustomResponseMessage,
} from "../../redux/actions";

const DataFilter = ({ posts, data }) => {
  const { t } = useTranslation();
  const { filterValue, currentCity,sortedPosts, currentPage,allTowns } = useSelector(
    (state) => state.posts
  );
    // const { user } = useSelector((state) => state.user);
    // const { city } = user || {};
  const dispatch = useDispatch();



  const handleChange = useCallback(
    (e) => {
      if (currentPage > 1) {
        dispatch(resetCurrentPage());
      }
      dispatch(handleFilterInputChange({ e, posts, setCustomResponseMessage }));
    },
    [posts, dispatch, currentPage]
  );

  const handleCityChange = useCallback(
    (e) => {
      if (currentPage > 1) {
        dispatch(resetCurrentPage());
      }
      dispatch(
        handleCityInputChange({ e, sortedPosts})
      );
    },
    [sortedPosts, dispatch, currentPage]
  );

  return (
    <div className="home__filter-container">
      <div className="home__filter pb-2">
        <label htmlFor="">{t("filter")}</label>
        <select
          className="home__filter-select"
          labelid="type-select"
          id="typeSelect"
          label="apartmentType"
          name="type"
          onChange={handleChange}
          value={filterValue}
          style={{ color: "#fff" }}
        >
          <option value="">{t("get all properties")}</option>
          {data?.map((option, index) => {
            return (
              <option key={index} value={option} style={{ color: "#fff" }}>
                {t(option)}
              </option>
            );
          })}
        </select>
      </div>
      
      <div className="home__filter">
        <label htmlFor="">{t("filter by city")}</label>
        <select
          className="home__filter-select"
          labelid="type-select"
          id="typeSelect"
          label="apartmentType"
          name="type"
          onChange={handleCityChange}
          value={currentCity}
        >
          {allTowns ? (
            <option value="">{t("city")}</option>
          ) : (
            <option value="">{t("location off")}</option>
          )}
          {allTowns?.map((option, index) => {
            return (
              <option key={index} value={option} style={{ color: "black" }}>
                {option}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default DataFilter;
