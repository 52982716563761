import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './loader.css'
import { loader } from '../../Assets/index';

const Loader = () => {
  const { t } = useTranslation()
  return (
    <div className='loader'>
       <img src={loader} alt="" /> 
       <Link to='/' className="loader__LogoLink px-3">
        {t("welcome note")}
        </Link>
    </div>
  )
}

export default Loader