import React from "react";
import { ImagesUploader } from "../../../../components";

const ImagesSection = ({
  previewImages,
  handleImageChange,
  removeImage,
  error,
  nextStep,
  validator,
  category

}) => (
    <ImagesUploader error={error} previewImages={previewImages}
    handleImageChange={handleImageChange} removeImage={removeImage} nextStep={nextStep} validator={validator}
    category={category}
    />
  );


export default ImagesSection;
