import React from 'react'
import { useSelector } from 'react-redux'
import AllRequestsScreen from './AllRequestsScreen/AllRequestsScreen'
import AllRentalProperties from './AllRentalProperties/AllRentalProperties'

const RentalsContainer = () => {
  const { currentTab } = useSelector((state) => state.posts)
  return (
    <>
     {
      currentTab === "houses" ? (
        <AllRentalProperties/>
      ) : (
        <AllRequestsScreen/> 
      )
     } 
    </>
  )
}

export default RentalsContainer