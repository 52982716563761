import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./navbar.css";
import { navLogo, profile } from "../../Assets/index";
import { useTranslation } from "react-i18next";
import { landingImages2 } from "../../dummyData/data";
import ImageSlider from "../ImageSlider/ImageSlider";
//import HelmetMetaData from "../HelmetMetaData/HelmetMetaData";
import { auth } from "../../firebaseConfig";
import { LANGUAGES } from "../../utils/utils";
import { AiOutlineMenu } from "react-icons/ai";
import { toggleMobileNavbar, singOutAction } from "../../redux/actions";
import Sidebar from "../../pages/Auth/Profile/Sidebar";
const Navbar = ({ displayName, isSubscribed }) => {
  const { t, i18n } = useTranslation();
  const { activeMenu } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const photoURL = auth?.currentUser?.photoURL;
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  const logOut = () => {
    dispatch(singOutAction(navigate));
  };
  //console.log(isSubscribed)
  return (
    <div className="main__nav">
      <div className="navbar__container">
        {/* <HelmetMetaData
            image="https://firebasestorage.googleapis.com/v0/b/housing-88dc5.appspot.com/o/postImages%2FFB_IMG_17380971879722448.jpg?alt=media&token=f0bcfacc-baa3-4e0f-b27e-027ad96d0679"
            currentUrl="https://www.homescraper.com/post/3lZHoHW67Xycgd1O13eT"
            description="We discover the latest rental properties and bring the agents closer to you with just one Click.So remember us whenever you need a new house 🔥🔥🔥🔥"
            quote="Bringing Home seekers,Homes and  Real Estate agents in one place"
            >
      
            </HelmetMetaData> */}
        <div className="navbar">
          <Link to="/" className="nav__logo_link">
            <div className="navbar__logo-container">
              <img src={navLogo} alt="logo" />
            </div>
            {/* <span className="website__name-navbar">H-SCRAPER</span> */}
          </Link>
          <AiOutlineMenu
            size={25}
            color="#4db5ff"
            onClick={() => dispatch(toggleMobileNavbar(activeMenu))}
            className="navbar__mobile-menu"
          />
          <div className="nav__linksContainer">
            <div className="">
              <select
                defaultValue={LANGUAGES[0]}
                className="nav__languageSelector"
                onChange={onChangeLang}
              >
                {LANGUAGES.map(({ code, label }, index) => (
                  <option
                    className="nav__language"
                    key={index}
                    value={code}
                    style={{
                      fontWeight:
                        i18n.resolvedLanguage === label ? "bold" : "normal",
                      textTransform: "uppercase",
                    }}
                  >
                    {t(label)}
                  </option>
                ))}
              </select>
            </div>
            {displayName ? (
              <div className="nav__links">
                <Link to="/for-sale" className="nav__link">
                  {" "}
                  {t("buy link")}
                </Link>
                <Link to="/" className="nav__link">
                  {" "}
                  {t("rent link")}
                </Link>

                <Link
                  to={`/profile/${displayName}`}
                  className="nav__link-profile"
                  style={{ color: "#4db5ff" }}
                >
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="avatar-container">
                      <img alt="avatar" src={photoURL ? photoURL : profile} />
                    </div>
                    {/* <span>{displayName}</span> */}
                  </div>
                </Link>
                <div className="profile__header">
                  <button onClick={logOut} className="btn btn-danger mx-2">
                    {t("logout")}
                  </button>
                </div>
              </div>
            ) : (
              <div className="nav__links">
                <Link to="/for-sale" className="nav__link-props">
                  {" "}
                  {t("buy link")}
                </Link>
                <Link to="/" className="nav__link-props">
                  {" "}
                  {t("rent link")}
                </Link>
                <Link to="/login" className="nav__link">
                  {" "}
                  {t("login")}
                </Link>
                <Link to="/register" className="nav__link">
                  {" "}
                  {t("register")}
                </Link>
              </div>
            )}
          </div>
        </div>
        {(window.location.pathname === "/" ||
          window.location.pathname === "/for-sale") && (
          <div className="navbar__slider">
            <ImageSlider images={landingImages2} />
          </div>
        )}
      </div>
      <div
        className={activeMenu ? "navbar__mobile-active" : "navbar__mobile"}
        onClick={() => dispatch(toggleMobileNavbar(activeMenu))}
      >
        <div
          className={
            activeMenu ? "navbar__mobile-head-active" : "navbar__mobile-head"
          }
        >
          <Link to="/" className="nav__logo_link">
            <div
              className={
                activeMenu
                  ? "navbar__logo-container"
                  : "navbar__logo-container-mobile"
              }
            >
              <img src={navLogo} alt="logo" />
            </div>
            {/* <span className="website__name-navbar">H-SCRAPER</span> */}
          </Link>
          <Link
            to="/for-sale"
            className={activeMenu ? "nav__link" : "nav__link-mobile"}
          >
            {" "}
            {t("buy link")}
          </Link>
          <Link
            to="/"
            className={activeMenu ? "nav__link" : "nav__link-mobile"}
          >
            {" "}
            {t("rent link")}
          </Link>
          <div className="">
            <select
              defaultValue={LANGUAGES[0]}
              className={
                activeMenu
                  ? "nav__languageSelector"
                  : "nav__languageSelector-mobile"
              }
              onChange={onChangeLang}
            >
              {LANGUAGES.map(({ code, label }, index) => (
                <option
                  className="nav__language"
                  key={index}
                  value={code}
                  style={{
                    fontWeight:
                      i18n.resolvedLanguage === label ? "bold" : "normal",
                    textTransform: "uppercase",
                  }}
                >
                  {t(label)}
                </option>
              ))}
            </select>
          </div>
          {/* <CloseIcon color="error" size="lg"/> */}
        </div>
        {displayName ? (
          <div
            className={
              activeMenu ? "nav__links-mobile-active" : "nav__links-mobile"
            }
          >
            <div className="w-100 d-flex flex-column justify-content-center">
              <Sidebar />
            </div>
          </div>
        ) : (
          <div
            className={
              activeMenu ? "nav__links-mobile-active" : "nav__links-mobile"
            }
          >
            <Link to="/for-sale" className="nav__link-props">
              {" "}
              {t("buy link")}
            </Link>
            <Link to="/" className="nav__link-props">
              {" "}
              {t("rent link")}
            </Link>
            <Link to="/login" className="nav__link">
              {" "}
              {t("login")}
            </Link>
            <Link to="/register" className="nav__link">
              {" "}
              {t("register")}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
