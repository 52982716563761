import React, { useState, useEffect } from "react";
import "./register.css";
import useValidator from "../../../utils/CustomValidator";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
// import { messageActions } from "../../redux/authSlice/messageSlice";
// import { registerUser } from "../../redux/authSlice/authSlice";
// import { useSelector, useDispatch } from 'react-redux';
import { loader } from "../../../Assets/index";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { clearMessageAction, RegisterAction } from "../../../redux/actions";
import {
  CustomInputField,
  CustomSelectCities,
  CustomSelectField,
} from "../../../components";
import { phoneNumberInputStyles, roles } from "../../../utils/utils";
import { scrollToTop } from "../../../utils/navUtils";

const Register = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const { message, isLoading, locationData } = useSelector(
    (state) => state.user
  );

  const { country, citiesData, stateCode } = locationData || [];
  console.log(country);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    // firstName: "",
    stateCode: stateCode,
    country: country,
    city: "",
    phoneNumber: phoneNumber,
    role: "",
    isSubscribed: false,
    isTrialDone: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const [validator, showValidationMessage] = useValidator();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop()
    dispatch(clearMessageAction());
  }, [dispatch]);

  useEffect(() => {
    const result = document
      .getElementById("phone__input")
      .getElementsByClassName("special-label");
    result[0].style.display = "none";
  }, []);

  const handlePhoneInputChange = (phone) => {
    setPhoneNumber(phone);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      dispatch(RegisterAction(formData, navigate));
    } else {
      showValidationMessage(true);
    }
  };

  return (
    <div className="register__container">
      <form className="register__form" onSubmit={handleSubmit}>
        <h3 className="register__header">
          {t("register message")}{" "}
          <span className="website__name">HOMESCRAPER</span>
        </h3>
        <CustomInputField
          inputType="text"
          placeholderText={t("username")}
          inputName="username"
          value={formData.username}
          handleInputChange={handleInputChange}
          className=""
        />
        <CustomInputField
          inputType="email"
          placeholderText={t("email")}
          inputName="email"
          value={formData.email}
          handleInputChange={handleInputChange}
          className=""
          required
        />
        {/*
          <CustomInputField
            inputType="text"
            placeholderText={t("first name")}
            name="firstName"
            value={formData.firstName}
           handleInputChange={handleInputChange}
            className=""
            required
          />

          <CustomInputField
            inputType="text"
            placeholderText={t("last name")}
            name="lastName"
            value={formData.lastName}
           handleInputChange={handleInputChange}
            className=""
            required
          />
*/}
        <CustomSelectField
          labelId="role-select"
          selectId="roleSelect"
          labelName="role"
          inputName="role"
          handleInputChange={handleInputChange}
          value={formData.role}
          data={roles}
          optionValue="for rent"
        />
        <CustomSelectCities
          labelId="city-select"
          selectId="citySelect"
          labelName="city"
          inputName="city"
          handleInputChange={handleInputChange}
          value={formData.city}
          data={citiesData}
          optionValue="for rent"
        />
        <div className="password">
          <CustomInputField
            inputType={showPassword ? "text" : "password"}
            placeholderText={t("password")}
            inputName="password"
            value={formData.password}
            handleInputChange={handleInputChange}
            className=""
            required
          />
          {showPassword ? (
            <AiFillEyeInvisible
              className="register__show-password"
              onClick={handleShowPassword}
            />
          ) : (
            <AiFillEye
              className="register__show-password"
              onClick={handleShowPassword}
            />
          )}
        </div>
        {/* <div className="register__input">
        <CustomInputField
          inputType={showPassword ? 'text' : 'password'} 
          placeholderText="Confirm Password"
          name='confirmPassword'
          value={formData.confirmPassword}
         handleInputChange={handleInputChange}
          className=""
          required
        />
         { showPassword ? <AiFillEyeInvisible className="register__show-password" onClick={handleShowPassword}/> :
        <AiFillEye className="register__show-password" onClick={handleShowPassword}/>}
          <label className="register__error text-danger">
            {validator.message("confirmPassword", formData.confirmPassword, "required|confirmPassword", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div> */}
        <div className="register__input" id="phone__input">
          <PhoneInput
            inputType="text"
            country={"cm"}
            enableAreaCodes={true}
            // onlyCountries={['us','de','ro']}
            // areaCodes={{us: ['999']}}
            inputProps={{
              name: "phoneNumber",
              country: "cm",
              required: true,
              autoFocus: false,
              backgroundColor: "none",
            }}
            // containerStyle={{
            //   Padding: "5px",
            // }}
            inputStyle={phoneNumberInputStyles}
            regions={["north-america", "africa", "europe"]}
            value={phoneNumber}
            onChange={handlePhoneInputChange}
            //handleInputChange={props.inputChange}
            // value={props.data.phonNumber}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
          <label className="error text-danger">
            {validator.message(
              "phoneNumber",
              phoneNumber,
              "required|phoneNumber",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="register__support">
          <Link to="/login" className="register__supportLink">
            {t("created account")}
          </Link>
        </div>
        {isLoading && <img src={loader} alt="" className="loading__image" />}
        <button inputType="submit" className="register__submit">
          {t("register")}
        </button>
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Register;
