import React from "react";

import { useSelector } from "react-redux";
import LandingScreen from "../LandingScreen/RentalsLandingScreen";
import { AllRentalsContainer } from "..";
import { MainHeader } from "../../components";
export const filterOptions = [
  "a-z",
  "modern studio",
  "modern room",
  "guest house",
  "simple apartment",
  "flat",
];
const HomeScreen = ({ posts,coords }) => {
  const { currentTab, refreshedUser } = useSelector((state) => state.posts);
  const { isSubscribed } = refreshedUser || {};

  return (
    <main
      className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
      id="top"
    >
      <MainHeader filteredApartments={posts} data={filterOptions} coords={coords}/>
      {isSubscribed && currentTab === "houses" ? (
        <AllRentalsContainer />
      ) : (
        <LandingScreen />
      )}
    </main>
  );
};

export default HomeScreen;
