// eslint-disable-next-line
import React, {
  useState,
  Suspense,
  useEffect,
  useCallback,
  useContext,
  lazy,
} from "react";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import { Helmet } from "react-helmet";
import { Loader, Navbar, Footer, ProtectedRoute } from "./components";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { IoIosArrowDropup } from "react-icons/io";
import useScrollPosition from "@react-hook/window-scroll";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAllPosts,
  getSubscriptionExpiryDataAction,
  unSubscriptionAction,
  getCurrentLocationCoordsAction,
  deleteOverduePostAction,
  setCustomResponseMessage,
  clearSearchTerm,
  getAllRequests,
  setLocationData,
  getCMRGeolocationDataAction,
  setSortedPostsAction,
  resetCurrentPage,
  setForSaleSortedAction,
  getGeolocationDataAction,
  setCurrencyAction,
} from "./redux/actions";
import { useNavigate } from "react-router-dom";
import {
  dateConverter,
  getCurrentCoordinatesAndLocation,
  LANGUAGES,
  today,
  // ,testBoundingBox
} from "./utils/utils";
//import { useGeolocated } from "react-geolocated";
import { navLinks } from "./utils/navUtils";
import { useGeolocated } from "react-geolocated";
import { Toaster } from "react-hot-toast";
import { IPInfoContext } from "ip-info-react";
import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { ForSaleScreen } from "./pages";
// import { getData,getData2,
//   getData3,
//   getData4 } from "./utils/test";

const HomeScreen = lazy(() =>
  delayForDemo(import("./pages/HomeScreen/HomeScreen"))
);

function App() {
  const {
    filteredPosts,
    forRent,
    forSale,
    searchTerm,
    posts,
    subscriptionExpiryDate,
    refreshedUser,
    customResponseMessage,
    geolocationData,
    //camGeoData,
    currentCity
  } = useSelector((state) => state.posts);
  const sortedRentalProperties = forRent?.slice(0, 100);
  const { user } = useSelector((state) => state.user);
  const { t,i18n } = useTranslation();
  const dispatch = useDispatch();
  const [displayScroll, setDisplayScroll] = useState(false);
  const scrollY = useScrollPosition(60 /*frames per second*/);
  const [currentStateCode, setCurrentStateCode] = useState("");
  const [filteredApartments, setFilteredApartments] = useState([]);
  const [filteredSalesProperties, setFilteredSalesProperties] = useState([]);
  const { id, displayName, city } = user || {};
  const { isSubscribed } = refreshedUser || {};
  const currentTown = city || geolocationData?.data?.city;
  const country = geolocationData?.data?.country;
  const userInfo = useContext(IPInfoContext);
  const { currency } = userInfo;
  // console.log(userInfo)

  const navigate = useNavigate();
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const location = useLocation();

  useEffect(() => {
  i18n.changeLanguage(LANGUAGES[0]?.label)
  }, [i18n]);
  useEffect(() => {
     dispatch(getAllPosts({currentTown,navigate,currentCity,country,currentStateCode,currency}));
    dispatch(getAllRequests());
    dispatch(setCurrencyAction(currency));
    dispatch(getCMRGeolocationDataAction(axios));
    dispatch(getGeolocationDataAction());
  }, [
    dispatch,
    user,
    currentTown,
    country,
    currentStateCode,
    currency,
    currentCity,
    navigate,
  ]);

  useEffect(() => {
    if (posts.length > 0) {
      dispatch(setSortedPostsAction(posts));
    }
  }, [dispatch, posts]);

  useEffect(() => {
   
    const getError = (error) => {
      console.error("Error getting geolocation:", error);
      dispatch(setCustomResponseMessage("location off"));
  
    }
    if (isGeolocationEnabled) {
    
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position);
            const { latitude, longitude } = position.coords;
            dispatch(setLocationData(latitude, longitude));
            const startingCoords2 = [longitude, latitude];
            const getData = async () => {
                const data = await getCurrentCoordinatesAndLocation(
                  latitude,
                  longitude
                );
                console.log(data);
                if (data) {
                  const startingNeighborhood = data?.suburb;
                  // console.log(startingNeighborhood);
                  dispatch(
                    getCurrentLocationCoordsAction(
                      startingCoords2,
                      startingNeighborhood
                    )
                  );
                  const { stateCode } = data;
                  setCurrentStateCode(stateCode);
                } else {
                  // navigate("/for-sale");
                  navigate("/network-error");
                }
            }
            getData();
          },
          
        );
      }else{
       
        setInterval(()=>{
          getError()
         },10000)
      }
  }, [
    dispatch,
    isGeolocationEnabled,
    coords,
    isGeolocationAvailable,
    t,
    navigate
  ]);

  useEffect(() => {
    if (id) {
      dispatch(getSubscriptionExpiryDataAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!displayName) return;
    if (isSubscribed === true && today >= subscriptionExpiryDate) {
      dispatch(unSubscriptionAction(id, isSubscribed, navigate));
    }
  }, [
    dispatch,
    subscriptionExpiryDate,
    id,
    isSubscribed,
    navigate,
    displayName,
  ]);

  useEffect(() => {
    if (forRent?.length > 0) {
      const postedDate = "6 months ago";
      forRent?.forEach((post, index) => {
        if (dateConverter(post?.post?.timestamp) === postedDate) {
          console.log("deleted", post?.id, index);
          dispatch(deleteOverduePostAction(post?.id));
        }
      });
    }
  }, [forRent, dispatch]);

  const notify = useCallback(() => {
    if (
      !customResponseMessage?.message?.match(`successful`) ||
      !customResponseMessage?.match(`successful`)
    ){
      toast.error(t(customResponseMessage), {
        duration: 7000,
        position: "top-center",
        removeDelay: 10000,
      });
    }
  }, [customResponseMessage,t]);

  useEffect(() => {
    if (customResponseMessage !== "") {
     notify()
    }
  }, [customResponseMessage,notify]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAIL_PUBLIC_KEY), []);
  useEffect(() => {
    if (scrollY >= 1530) {
      setDisplayScroll(true);
    } else {
      setDisplayScroll(false);
    }
  }, [scrollY]);

  // useEffect(() => {
  //   if (searchTerm !== "") {
  //     dispatch(setSortedPostsAction(filteredPosts))
  //     setFilteredApartments(filteredPosts);
  //   }
  // }, [searchTerm,dispatch,filteredPosts]);

  useEffect(() => {
    if (searchTerm === "") {
      if (!displayName && !isSubscribed) {
        setFilteredApartments(sortedRentalProperties);
        setFilteredSalesProperties(forSale);
        dispatch(setSortedPostsAction(sortedRentalProperties));
        dispatch(setForSaleSortedAction(forSale));
      } else if (displayName && isSubscribed) {
        dispatch(resetCurrentPage());
        setFilteredApartments(forRent);
        dispatch(setSortedPostsAction(forRent));
        setFilteredSalesProperties(forSale);
        dispatch(setForSaleSortedAction(forSale));
      }
    } else {
      dispatch(setSortedPostsAction(filteredPosts));
      setFilteredApartments(filteredPosts);
      setFilteredSalesProperties(filteredPosts);
      dispatch(setForSaleSortedAction(filteredPosts));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, forRent, isSubscribed, filteredPosts, searchTerm, displayName]);

  useEffect(() => {
    if (location.pathname.match("/no-data-fallback") && searchTerm !== "") {
      setInterval(() => {
        dispatch(clearSearchTerm());
      }, 5000);
    }
  }, [searchTerm, location, dispatch, navigate]);

  if (posts.length === 0 && window.location.pathname !== "/network-error") {
    return <Loader />;
  }
 
  // console.log(camGeoData)
  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <Helmet>
          <title>
            Real Estate & Homes for Sale in Cameroon,Rentals,Guest House
          </title>
          <meta
            name="description"
            content="Find guest houses for breakfast and bed (B&B), houses to rent,houses and plots of land for sell,and solve all your real estate problems.We have brought thousands of homes,plots of land,guest houses and real estate agents closer to you.From the comfort of your home and with your phone or laptop,access our thousand of property and speak with real estate agents with one click"
          />
          <meta
            name="keywords"
            content="real estate in Cameroon, homes for sale in Cameroon,rentals,guest house "
          />
        </Helmet>
        <Navbar displayName={displayName} isSubscribed={isSubscribed} />
        {/* {(window.location.pathname === "/" ||
          window.location.pathname === "/for-sale") && (
          <div className="search__container">
            <Search />
          </div>
        )} */}

        <Toaster
          gutter={24}
          containerStyle={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20,
            width: "100%",
          }}
          toastOptions={{
            className: "",
            duration: 10000,
            removeDelay: 10000,
          }}
        />

        <Routes>
          <Route path="/" 
          element={<HomeScreen posts={filteredApartments} coords={coords}/>} />
          <Route
            path="/for-sale"
            element={<ForSaleScreen posts={filteredSalesProperties} />}
          />
          {navLinks?.slice(0, 7)?.map((item) => (
            <Route
              path={item.link}
              key={item.link}
              element={<item.element />}
            />
          ))}
          {/* protected routes */}
          <Route element={<ProtectedRoute />}>
            {navLinks?.slice(7, 19)?.map((item) => (
              <Route
                path={item.link}
                key={item.link}
                element={<item.element posts={posts} id={id} />}
              />
            ))}
          </Route>
        </Routes>
          {displayScroll && (
           <div className="app__topIcon-container"  onClick={scrollToTop}>
            <IoIosArrowDropup
              size={30}
              color="#ff0000"
              className=""
             
            />
             <a href="#top" className="app__topIcon">{t("back to top")}</a>
           </div>
          )}
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function delayForDemo(promise) {
  return new Promise((resolve) => {
    setTimeout(resolve, 30);
  }).then(() => promise);
}
